import React, { useState, useCallback, useEffect } from 'react';
import { SM, MD } from '@zendeskgarden/react-typography';
import { useBranch } from 'baobab-react/hooks';
import { isObject } from 'lodash';

import DropZoneFileManager from 'components/DropZone/DropZoneFileManager';
import {
  DropZoneFileManagerData,
  FileUpload,
  UploadScrapValueResponse,
} from 'types/types.ts';
import { DOMAIN } from 'constants.js';
import { uploadScrapValueSpreadsheet } from 'state/scrapValue/actions.ts';
import { useTaskStatus } from 'hooks/useTaskStatus.ts';
import { NewAlert } from 'theme/Alert';
import { Button, emptyButtonProps } from 'theme/Button';

interface AddScrapValueUploadProps {
  onSuccess?: () => void;
}

export function AddScrapValueUpload({ onSuccess }: AddScrapValueUploadProps) {
  const [dropZoneData, setDropZoneData] =
    useState<DropZoneFileManagerData | null>(null);
  const [submitTrigger, setSubmitTrigger] = useState(0);

  const [incorrectFileFormatWarning, setIncorrectFileFormatWarning] = useState<
    string | null
  >(null);
  const [isPreparingUploadSpreadsheet, setIsPreparingUploadSpreadsheet] =
    useState(false);
  const [uploadSpreadsheetError, setUploadSpreadsheetError] = useState<
    string | null
  >(null);
  const [displayFileUploadSuccess, setDisplayFileUploadSuccess] =
    useState(false);

  const { waitForTaskResult } = useTaskStatus();
  const { rfqMeta } = useBranch({
    rfqMeta: ['requestForQuote', 'meta', 'result'],
  });

  const marketScrapContentId = rfqMeta?.file_content_types.MarketScrap;
  const files = Object.values(dropZoneData?.files || {});
  const fileIsSaved = files.length > 0 && !!files[0].saved;

  useEffect(() => {
    const file = files[0];
    const fileExtension = file ? file.name.split('.').pop() : '';
    if (file && fileExtension !== 'xlsx') {
      setIncorrectFileFormatWarning('File must be in .xlsx format.');
    }
  }, [files]);

  const handleClickUpload = useCallback(() => {
    setIsPreparingUploadSpreadsheet(true);
    setSubmitTrigger((previous) => previous + 1);
  }, []);

  const uploadSpreadsheet = useCallback(
    async (savedFiles: FileUpload[]) => {
      const fileSaved = savedFiles?.[0].saved;
      let s3FilePath;
      if (isObject(fileSaved)) {
        const fileUri = fileSaved.file;
        const [beforeQuerystring] = fileUri.split('?');
        const [, path] = beforeQuerystring.split(`${DOMAIN}/`);
        s3FilePath = path;
      }

      if (s3FilePath) {
        setUploadSpreadsheetError(null);
        const response = await uploadScrapValueSpreadsheet({
          uri: s3FilePath,
          timezone_offset: new Date().getTimezoneOffset(),
        });
        if (response.error) {
          setUploadSpreadsheetError(response.error);
        } else {
          try {
            const taskResult =
              await waitForTaskResult<UploadScrapValueResponse>(
                response.task_id
              );
            if (!taskResult) throw new Error('Missing uri or filename');
            onSuccess?.();
            setDisplayFileUploadSuccess(true);
          } catch (taskError) {
            const _taskError = taskError as Error;
            setUploadSpreadsheetError(_taskError.message);
          }

          setIsPreparingUploadSpreadsheet(false);
        }
      }
    },
    [onSuccess, waitForTaskResult]
  );

  const handleDeleteFile = useCallback(() => {
    setDisplayFileUploadSuccess(false);
    setUploadSpreadsheetError(null);
  }, []);

  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        <MD navy bold style={{ marginTop: '20px' }}>
          Upload Spreadsheet
        </MD>
        <SM slate>Upload a spreadsheet with new values here.</SM>
      </div>
      <DropZoneFileManager
        externalSubmit
        hidePreviousUploads
        type="upload"
        multiple={false}
        uploadOnDrop={false}
        isImage={false}
        contentType={marketScrapContentId}
        setExternalState={setDropZoneData}
        onSaved={uploadSpreadsheet}
        onDelete={handleDeleteFile}
        errorResubmitKey={submitTrigger}
        height="150px"
        outerWrapperStyle={{
          width: '100%',
          marginTop: '30px',
          marginBottom: '-20px',
        }}
      />
      {incorrectFileFormatWarning && (
        <NewAlert title={incorrectFileFormatWarning} type="warning" />
      )}
      <Button
        {...emptyButtonProps}
        primary
        onClick={handleClickUpload}
        disabled={
          !!incorrectFileFormatWarning ||
          files.length === 0 ||
          isPreparingUploadSpreadsheet ||
          fileIsSaved
        }
        loading={isPreparingUploadSpreadsheet}
        style={{ marginBottom: '20px' }}
      >
        Upload .XLSX
      </Button>
      {isPreparingUploadSpreadsheet && (
        <SM slate style={{ marginTop: '-15px' }}>
          The new data is being saved. Please wait...
        </SM>
      )}
      {uploadSpreadsheetError && (
        <NewAlert
          label="Upload .XLSX Error"
          title={uploadSpreadsheetError}
          type="error"
        />
      )}
      {displayFileUploadSuccess && (
        <NewAlert title="New Scrap Value uploaded!" type="success" />
      )}
    </>
  );
}
