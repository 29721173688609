import React from 'react';
import Icon from 'components/Icon/Icon';
import Flex from 'styled-flex-component';
import { IconButton } from '@zendeskgarden/react-buttons';
import { MD } from '@zendeskgarden/react-typography';

import { closeSidebar } from 'state/sidebars/actions';
import { Button, emptyButtonProps } from 'theme/Button';

import {
  SidebarFilterHeader,
  SidebarContentWrapper,
  SidebarFilterBody,
} from '../../GlobalSideBar.styles';
import { AddScrapValueDownload } from './components/AddScrapValueDownload.tsx';
import { AddScrapValueUpload } from './components/AddScrapValueUpload.tsx';
import { Divider } from './AddScrapValueSidebar.styles.ts';

interface AddScrapValueSidebarProps {
  options: {
    onSuccess?: () => void;
  };
}

export function AddScrapValueSidebar({ options }: AddScrapValueSidebarProps) {
  return (
    <SidebarContentWrapper>
      <SidebarFilterHeader>
        <Flex full justifyBetween alignCenter>
          <Flex alignCenter>
            <MD scaled bold color="#fff">
              Add New Scrap Value
            </MD>
          </Flex>
          <IconButton
            onClick={closeSidebar}
            disableBackground
            alignCenter
            justifyEnd
            size="large"
            aria-label="closeSidebar"
          >
            <Icon button icon="icon-close" fontSize="13px" color="#fff" />
          </IconButton>
        </Flex>
      </SidebarFilterHeader>
      <SidebarFilterBody>
        <Flex column alignCenter>
          <AddScrapValueDownload />
          <Divider />
          <AddScrapValueUpload onSuccess={options.onSuccess} />
          <Divider />
          <Button
            {...emptyButtonProps}
            primary
            onClick={closeSidebar}
            style={{ marginTop: '20px' }}
          >
            Close
          </Button>
        </Flex>
      </SidebarFilterBody>
    </SidebarContentWrapper>
  );
}
