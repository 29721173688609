import React from 'react';
import { fetchFile } from 'state/application/actions';
import { SM } from '@zendeskgarden/react-typography';
import { Row, Col } from '@zendeskgarden/react-grid';
import { variables } from 'theme/variables';
import AcrobatDownload from 'svg/acrobatDownload';
import Icon from 'components/Icon/Icon';
import { includes } from 'lodash';
import { PropTypes } from 'prop-types';
import { humanFileSize } from 'utility/downloadFile';
import { DocumentWrapper } from './DocumentDownload.styles';

const {
  spacing_xxs: spacingXxs,
  custom_silver: customSilver,
  color_red_400: colorRed400,
} = variables;

const documentItemDefault = {};

function DocumentDownload({
  style,
  disableDelete,
  onRemoveFile,
  documentItem = documentItemDefault,
  legacy = false,
  filesize,
}) {
  // Legacy files are a different endpoint that carried over from PipeSearch analytics. There are some
  // files like alloy/metallurgy that are legacy, and hence have a different endpoint.
  const { title = 'Document', fileId } = documentItem;
  let { type } = documentItem;
  type =
    (documentItem?.file || title) &&
    includes(documentItem?.file || title, '.pdf')
      ? 'PDF'
      : type || 'Document';

  async function downloadFile() {
    const file = await fetchFile(fileId, { legacy });

    if (file.error && file.loading) {
      alert(file.error);
    } else {
      window.open(file.file);
    }
  }

  return (
    <DocumentWrapper
      style={style || {}}
      className="document-download-wrapper"
      onClick={() => downloadFile()}
    >
      <Row>
        <Col alignCenter size="auto" className="icon">
          <AcrobatDownload style={{ height: '40px' }} type={type} />
        </Col>

        <Col className="title-col">
          <SM className="title" style={{ lineHeight: '18px' }} noMargin>
            {title}
          </SM>
          <SM className="type" uppercase style={{ paddingTop: spacingXxs }}>
            {type}
          </SM>
          {filesize ? (
            <SM className="type" style={{ paddingTop: spacingXxs }}>
              {humanFileSize(filesize)}
            </SM>
          ) : null}
        </Col>

        <Col className="delete-icon" size="auto" alignCenter>
          {!disableDelete ? (
            <Icon
              icon="icon-delete"
              color={customSilver}
              hoverColor={colorRed400}
              fontSize="18px"
              buttonStyle
              onClick={(e) => {
                e.stopPropagation();
                if (
                  window.confirm(`Are you sure you want to delete ${title}`)
                ) {
                  onRemoveFile(e);
                }
              }}
            />
          ) : null}
        </Col>
      </Row>
    </DocumentWrapper>
  );
}

DocumentDownload.propTypes = {
  style: PropTypes.shape({}),
  disableDelete: PropTypes.bool,
  onRemoveFile: PropTypes.func,
  documentItem: PropTypes.shape({
    title: PropTypes.string,
    fileId: PropTypes.number,
    type: PropTypes.string,
    file: PropTypes.string,
  }),
  legacy: PropTypes.bool,
  filesize: PropTypes.number,
};

export default DocumentDownload;
