import fetchJson from 'utility/fetchJson';
import { Filters } from 'types/types.ts';
import { BASE } from '../../constants';

interface GetScrapValueOptions {
  filters?: Filters;
  pagination?: {};
  sorting?: {};
}

export async function getScrapValue({
  filters,
  pagination,
  sorting = {},
}: GetScrapValueOptions) {
  const body = { ...filters, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/scrap/list/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export interface GetScrapSpreadSheetPayload {
  company_id: number | null;
  contact_id: number | null;
}

export async function getScrapSpreadsheet(data: GetScrapSpreadSheetPayload) {
  const response = await fetchJson(
    `${BASE}/api/scrap/generate_scrap_spreadsheet/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
  return response;
}

export interface UploadScrapSpreadsheetPayload {
  uri: string;
  timezone_offset?: number;
}

export async function uploadScrapSpreadsheet(
  data: UploadScrapSpreadsheetPayload
) {
  const response = await fetchJson(
    `${BASE}/api/scrap/upload_scrap_spreadsheet/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
  return response;
}
