import {
  IServerSideGetRowsRequest,
  SelectionEventSourceType,
} from 'ag-grid-community';

/**
 * AgGrid Filter Model types
 */
export enum PSAgGridFilterModelType {
  set = 'set',
  number = 'number',
  text = 'text',
}

interface PSAgGridFilterModelBase {
  filterType: PSAgGridFilterModelType;
  boost?: number;
}

interface PSAgGridFilterModelIsNull {
  type: 'isNull';
}

export interface PSAgGridFilterModelSet extends PSAgGridFilterModelBase {
  filterType: PSAgGridFilterModelType.set;
  values: (string | number | null)[];
  /* This is used by Set Filter Values getter (`generateAgGridFieldValuesGetter`).
  If we specify in `injectPayload` some values, that used must be limited to, we
  can specify them here. */
  limitedValues?: (string | number | null)[];
}

export interface PSAgGridFilterModelText extends PSAgGridFilterModelBase {
  filterType: PSAgGridFilterModelType.text;
  type:
    | 'contains'
    | 'notContains'
    | 'startsWith'
    | 'endsWith'
    | 'equals'
    | 'notEqual';
  filter: string;
}

export interface PSAgGridFilterModelNumber extends PSAgGridFilterModelBase {
  filterType: PSAgGridFilterModelType.number;
  type:
    | 'equals'
    | 'notEqual'
    | 'lessThan'
    | 'lessThanOrEqual'
    | 'greaterThan'
    | 'greaterThanOrEqual'
    | 'inRange';
  filter: number;
  filterTo?: number;
}

interface PSAgGridFilterModelWithOperator<T extends PSAgGridFilterModelBase> {
  operator: 'AND' | 'OR';
  condition1: T;
  condition2: T;
  filterType: T['filterType'];
}

type PSAgGridFilterModelMaybeDoubleCondition<
  T extends PSAgGridFilterModelBase
> = T | PSAgGridFilterModelWithOperator<T>;

type PSAgGridFilterModelData =
  | PSAgGridFilterModelSet
  | PSAgGridFilterModelMaybeDoubleCondition<PSAgGridFilterModelText>
  | PSAgGridFilterModelMaybeDoubleCondition<PSAgGridFilterModelNumber>
  | PSAgGridFilterModelIsNull;

export type PSAgGridFilterModel<K = Record<string, unknown>> = Partial<
  Record<keyof K, PSAgGridFilterModelData>
>;

export const isFieldSetFilterModel = (
  fieldFilterModel: PSAgGridFilterModelData | undefined
): fieldFilterModel is PSAgGridFilterModelSet =>
  !!(
    fieldFilterModel &&
    'filterType' in fieldFilterModel &&
    fieldFilterModel.filterType === PSAgGridFilterModelType.set
  );

export enum PSAgGridColumnFilterType {
  text = 'agTextColumnFilter',
  number = 'agNumberColumnFilter',
  set = 'agSetColumnFilter',
  date = 'agDateColumnFilter',
}

export enum SolrCore {
  content = 'analytics__content',
  inventory = 'analytics__inventory',
}

export enum DocumentType {
  PSCampaign = 'PSCampaign',
  PSCampaignRecipient = 'PSCampaignRecipient',
  PSUser = 'PSUser',
  PSProspectingSearch = 'PSProspectingSearch',
  PSSupplyOpportunity = 'PSSupplyOpportunity',
  PSSupplyOpportunityItem = 'PSSupplyOpportunityItem',
  PSCompany = 'PSCompany',
  CRAInventoryItemDetails = 'CRAInventoryItemDetails',
  PSRFQ = 'PSRFQ',
  PSRFQItem = 'PSRFQItem',
  PSMRFQ = 'PSMRFQ',
  PSMRFQItem = 'PSMRFQItem',
  MarketScrap = 'MarketScrap',
}

/** Temporary until we rewrite consumers to typescript */
export const PSAgGridColumnFilterTypeText = PSAgGridColumnFilterType.text;
export const PSAgGridColumnFilterTypeNumber = PSAgGridColumnFilterType.number;
export const PSAgGridColumnFilterTypeSet = PSAgGridColumnFilterType.set;
export const PSAgGridColumnFilterTypeDate = PSAgGridColumnFilterType.date;
export const PSAgGridSolrCoreContent = SolrCore.content;
export const PSAgGridDocumentTypePSCampaign = DocumentType.PSCampaign;
export const PSAgGridDocumentTypePSUser = DocumentType.PSUser;

/** Our backend requires only these fields to be present in the payload. The rest is optional. */
type AgGridPayloadOverwrite = Partial<IServerSideGetRowsRequest> &
  Pick<IServerSideGetRowsRequest, 'startRow' | 'endRow' | 'filterModel'>;
/** We also want to have a typed filterModel and a support for filterModelOrOperands which the backend supports. */
export interface AgGridPayload<T = Record<string, unknown>>
  extends AgGridPayloadOverwrite {
  filterModel: PSAgGridFilterModel<T>;
  filterModelOrOperands?: PSAgGridFilterModel<T>[];
}

export type InjectAgGridPayloadFn<T = Record<number | string, unknown>> = (
  payload: AgGridPayload<T>
) => AgGridPayload<T> | undefined;

/** Identifies when a checkbox is selected by user action */
export const PSAgGridCheckboxSelectedSourceType: SelectionEventSourceType =
  'checkboxSelected';

export type AgGridResponse<T> = {
  docs: T[];
  extra_query?: string;
  facets: Record<string, unknown>;
  filter_model: PSAgGridFilterModel<T>;
  filter_model_or_operands?: PSAgGridFilterModel<T>[];
  limit: number;
  num_pages: number;
  page: number;
  sort_fields: string[];
  total: number;
};

/** This lets us define a AgGrid Request with a typical AgGrid table payload (in filters.agGridData)
 * and additional data that might help with filtering */
export type AgGridRequest<
  T = Record<string, unknown>,
  U = Record<string, unknown>
> = {
  /** This is atemporary structure, so our AgGrid component can work both with old and new implementations.
   * In the future we should rething this. */
  filters: {
    agGridData: AgGridPayload<T>;
  };
} & U;
