import {
  isObject,
  omit,
  isEmpty,
  reduce,
  pickBy,
  identity,
  map,
  has,
  filter,
  isEqual,
  get,
  find,
  isArray,
  includes,
  sortBy,
  compact,
  reject,
  cloneDeep,
  forEach,
  unset,
} from 'lodash';
import tree from 'state';

import { getScalarUOM, getUnitUOM, unitLabels } from 'utility/staticMap.ts';
import { baseValidation } from './validation_items';
import reverseItemFilters from './reverser_items';

// CURRENT OPTIONS
// title - required
// minDropdownWidth - sets dropdown min width
// disableSelectionOnRange - dont allow selection when range
// items - renders list options else renders form input
// operators - adds top right dropdown
// operator - selected default operator
// operatorIsValue - should operator value be sent to api
// templates - associated template
// disabled - hide input on specific template - dont include data or component in saving updating fetching
// filter - filter item options when input changes
// memo - memoize component for performance
// onUpdate - call everytime tree changes
// warning - warning UI
// error - error UI
// customValues - can input use custom values
// inputType - custom UI for type ie 'FilterItemSelection'
// FilterItemSelection can take selectionFilters - CUSTOM UI
// Dirty - has the field been touched
// Value - comes from server
// hidden - value is usually defaulted but the UI is hidden
// multiple [for alloy and end_finish] (boolean): Default: true. Setting false allows only one selection in these fields

// Disable range selection based on form state
// Store the previous configuration values so they can be re-enabled
export function toggleRangeSelection(filters, { allowRange }) {
  const _filters = cloneDeep(filters);
  const eligibleFields = ['od', 'inside_diameter', 'wall', 'min_yield'];

  if (allowRange) {
    forEach(eligibleFields, (field) => {
      // Only set these values if they haven't been set already
      if (!_filters[field].operators) {
        _filters[field].operators = _filters[field].operators_disabled;
        unset(_filters[field], 'operators_disabled');
        _filters[field].operator = _filters[field].operator_disabled;
        unset(_filters[field], 'operator_disabled');
        if (_filters[field].value?.value) {
          const currentOperator = _filters[field].operator;
          if (currentOperator?.value === 'range') {
            _filters[field].value.min = { ..._filters[field].value };
            unset(_filters[field].value, 'value');
            unset(_filters[field].value, 'label');
          }
        }
      }
    });
  } else {
    forEach(eligibleFields, (field) => {
      // Only set these values if they haven't been set already
      if (!_filters[field].operators_disabled) {
        _filters[field].operators_disabled = _filters[field].operators;
        unset(_filters[field], 'operators');
        _filters[field].operator_disabled = _filters[field].operator;
        unset(_filters[field], 'operator');
        if (_filters[field].value?.min) {
          _filters[field].value = { ..._filters[field].value.min };
          unset(_filters[field].value, 'min');
          unset(_filters[field].value, 'max');
        }
      }
    });
  }
  return _filters;
}

const operators = [
  { label: 'Equals', value: 'equals' },
  { label: 'Range', value: 'range' },
];

function getMetadata() {
  return (
    tree.select(['requestForQuote']).get(['meta', 'result']) ||
    tree.select(['onboarding']).get(['meta', 'result']) ||
    tree.select(['metadata']).get(['rfqMetadata', 'result']) ||
    tree.select(['metadata']).get(['onboardingMetadata', 'result'])
  );
}

export function generateRfqOtherMetaInformation(
  _metadata,
  serverValues,
  _instanceOptions,
  localSeed,
  unsaved
) {
  return {
    temp: unsaved || !serverValues,
    name: serverValues && serverValues.name,
    saved: unsaved ? false : !!serverValues,
    valid: unsaved ? false : !!serverValues,
    technicalDetailsConfirmed: serverValues
      ? !!serverValues.technical_details_confirmed
      : false,
    notes: {
      seed: (serverValues && serverValues.notes) || '',
      data: (serverValues && serverValues.notes) || '',
    },
    finishedSize: serverValues?.finished_size || localSeed?.finishedSize,
    finishedSizeState:
      serverValues?.finished_size_state ||
      localSeed?.finishedSizeState ||
      false,
  };
}

function disabledByDefault(
  enabledTemplates,
  serverVals,
  localSeed,
  defaultVal,
  { enableOverride, disableOverride } = {}
) {
  const category =
    get(serverVals, 'category_object_prefix') ||
    localSeed?.category?.value?.template;
  if (enableOverride) {
    return false;
  }
  if (disableOverride) {
    return true;
  }
  if (category) {
    const includedInEnabledTemplates = includes(enabledTemplates, category);
    return !includedInEnabledTemplates;
  }
  return defaultVal;
}

function getDefaultCategory(categoryList, isMfg) {
  if (isMfg) {
    const mechanicalTubeCategory = find(categoryList, {
      object_prefix: 'Mechanical',
    });
    const mechanicalTubeItem = mechanicalTubeCategory && {
      label: mechanicalTubeCategory.name,
      value: mechanicalTubeCategory.id,
      template: mechanicalTubeCategory.object_prefix,
    };
    return mechanicalTubeItem;
  }
  return undefined;
}

export function generateItemFilters(metadata, serverValues, options = {}) {
  const _metadata = metadata || getMetadata() || {};
  const { localSeed, isCreating, disableRange, isMfg } = options; // rename disableRange to e.g. toggleRangeState
  const hiddenFields = options.hidden || {};
  const {
    category,
    standard_od: standardOd,
    standard_weight: standardWeight,
    metallurgy_group: metallurgyGroup,
    creation_metallurgy_group: creationMetallurgyGroup,
    metallurgy,
    creation_metallurgy: creationMetallurgy,
    range,
    end_finish: endFinish,
    end_finish_category: endFinishCategory,
    end_finish_licensee: endFinishLicensee,
    end_finish_thread_type: endFinishThreadType,
    brand,
  } = _metadata || {};
  const { finished_size_state: finishedSizeState } = serverValues || {};
  const metallurgyFinal =
    isCreating && creationMetallurgy ? creationMetallurgy : metallurgy;
  const metallurgyGroupFinal =
    isCreating && creationMetallurgy
      ? creationMetallurgyGroup
      : metallurgyGroup;

  let config = {
    category: {
      title: 'Category',
      hidden: hiddenFields.category, // hide for wishlist.
      multiple: get(options, 'category.multiple'),
      validation: (args) =>
        baseValidation({
          ...args,
          validationOptions: { isString: true },
        }),
      minDropdownWidth: '300px',
      items:
        map(category, ({ name, id, object_prefix: objectPrefix }) => ({
          value: id,
          label: name,
          template: objectPrefix,
        })) || [],
      value: getDefaultCategory(category, isMfg),
      columnSize: get(options, 'category.columnSize'),
    },

    od: {
      title: 'OD',
      operators: options.disableRangeFields ? undefined : operators,
      operator: options.defaultRange ? operators[1] : undefined,
      validation: baseValidation,
      templates: ['OCTG', 'Mechanical', 'Coupling', 'Bar', 'Shell'],
      disabled: disabledByDefault(
        ['OCTG', 'Mechanical', 'Coupling', 'Bar'],
        serverValues,
        localSeed,
        false,
        {
          enableOverride: get(options, 'od.enabled'),
        }
      ),
      customValues: true,
      items:
        map(standardOd, ({ name, od, id }) => ({
          rangeFilterValue: Number(od),
          value: od,
          label: name,
          id,
        })) || [],
      filter: (items, currentFilters) =>
        get(currentFilters, 'category.value.template', 'OCTG') === 'OCTG'
          ? items
          : null, // we can return null to force custom values
      memo: (pp, np) =>
        get(pp, 'currentFilters.category.value.template') ===
        get(np, 'currentFilters.category.value.template'),
      columnSize: get(options, 'od.columnSize'),
    },

    inside_diameter: {
      title: 'ID',
      operators: options.disableRangeFields ? undefined : operators,
      operator: options.defaultRange ? operators[1] : undefined,
      templates: ['Mechanical', 'Shell'],
      disabled: disabledByDefault(
        ['Mechanical', 'Shell'],
        serverValues,
        localSeed,
        true,
        {
          enableOverride: get(options, 'inside_diameter.enabled'),
        }
      ),
      validation: baseValidation,
      columnSize: get(options, 'inside_diameter.columnSize'),
    },

    wall: {
      title: 'Wall',
      operators: options.disableRangeFields ? undefined : operators,
      operator: options.defaultRange ? operators[1] : undefined,
      templates: ['Mechanical', 'Shell'],
      disabled: disabledByDefault(
        ['Mechanical', 'Shell'],
        serverValues,
        localSeed,
        true,
        {
          enableOverride: get(options, 'wall.enabled'),
        }
      ),
      validation: baseValidation,
      columnSize: get(options, 'wall.columnSize'),
    },

    weight_per_foot: {
      title: '#/FT',
      operators: options.disableRangeFields ? undefined : operators,
      operator: options.defaultRange ? operators[1] : undefined,
      templates: ['OCTG', 'Coupling'],
      disabled: disabledByDefault(
        ['OCTG', 'Coupling'],
        serverValues,
        localSeed,
        false,
        {
          enableOverride: get(options, 'weight_per_foot.enabled'),
        }
      ),
      customValues: true,
      validation: baseValidation,
      items: map(
        standardWeight,
        ({
          name,
          weight_per_foot: weightPerFoot,
          standard_od: _standardOd,
        }) => ({
          rangeFilterValue: Number(weightPerFoot),
          value: weightPerFoot,
          label: name,
          standard_od: _standardOd,
        })
      ),
      filter: (weights, meta) => {
        const odMinValue = get(meta.od.value, 'min.rangeFilterValue');
        const odMaxValue = get(meta.od.value, 'max.rangeFilterValue');
        let odItems;
        if (odMinValue && odMaxValue) {
          odItems = filter(
            meta.od.items,
            (od) =>
              od.rangeFilterValue >= odMinValue &&
              od.rangeFilterValue <= odMaxValue
          );
        }
        const filtered = filter(weights, (weight) => {
          if (odMinValue && odMaxValue) {
            return !!find(odItems, { id: weight.standard_od });
          }
          const odId = get(meta.od.value, 'id');
          if (odId) {
            return odId === weight.standard_od;
          }
          return true;
        });
        return sortBy(filtered, 'rangeFilterValue');
      },
      memo: (pp, np) =>
        isEqual(pp.currentFilters.od.value, np.currentFilters.od.value),
      columnSize: get(options, 'weight_per_foot.columnSize'),
    },

    alloy: {
      inputType: 'FilterItemSelection',
      title: 'Alloy',
      templates: ['OCTG', 'Mechanical', 'Coupling', 'Bar', 'Shell'],
      customValues: true,
      disabled: disabledByDefault(
        ['OCTG', 'Mechanical', 'Coupling', 'Bar', 'Shell'],
        serverValues,
        localSeed,
        false,
        {
          enableOverride: get(options, 'alloy.enabled'),
        }
      ),
      multiple: options?.alloy?.multiple,
      validation: (args) =>
        baseValidation({
          ...args,
          validationOptions: {
            isValidFn: (v) =>
              (v && v.length) ||
              get(args, 'item.selectedFilters.groups.length'),
          },
        }),
      selectionFilters: {
        groups: map(metallurgyGroupFinal, ({ name, id }) => ({
          value: id,
          label: name,
        })),
      },
      items: map(
        metallurgyFinal,
        ({ name, id, metallurgy_group: _metallurgyGroup }) => ({
          value: id,
          label: name,
          group: _metallurgyGroup,
        })
      ),
      columnSize: get(options, 'alloy.columnSize'),
    },
    min_yield: {
      title: 'Yield',
      operators: options.disableRangeFields ? undefined : operators,
      operator: options.defaultRange ? operators[1] : undefined,
      uom: 'KSI',
      templates: ['OCTG', 'Mechanical', 'Coupling', 'Bar'],
      disabled: disabledByDefault(
        ['OCTG', 'Mechanical', 'Coupling', 'Bar', 'Shell'],
        serverValues,
        localSeed,
        false,
        {
          enableOverride: get(options, 'min_yield.enabled'),
        }
      ),
      inputType: 'number',
      validation: baseValidation,
      columnSize: get(options, 'min_yield.columnSize'),
    },
    end_finish: {
      inputType: 'FilterItemSelection',
      title: 'End Finish',
      customValues: true,
      templates: ['OCTG', 'Coupling'],
      disabled: disabledByDefault(
        ['OCTG', 'Coupling'],
        serverValues,
        localSeed,
        false,
        {
          enableOverride: get(options, 'end_finish.enabled'),
        }
      ),
      multiple: options?.end_finish?.multiple,
      validation: (args) =>
        baseValidation({
          ...args,
          validationOptions: {
            isValidFn: (v) =>
              (v && v.length) || get(args, 'item.selectedFilters.length'),
          },
        }),
      selectionFilters: {
        end_finish_category: map(endFinishCategory, ({ name, id }) => ({
          type: 'end_finish_category',
          value: id,
          label: name,
        })),
        end_finish_licensee: map(endFinishLicensee, ({ name, id }) => ({
          type: 'end_finish_licensee',
          value: id,
          label: name,
        })),
        end_finish_thread_type: map(endFinishThreadType, ({ name, id }) => ({
          type: 'end_finish_thread_type',
          value: id,
          label: name,
        })),
      },
      items: map(
        endFinish,
        ({
          category: _category,
          id,
          licensee,
          name,
          thread_type: threadType,
        }) => ({
          value: id,
          label: name,
          end_finish_category: _category,
          end_finish_thread_type: threadType,
          end_finish_licensee: licensee,
        })
      ),
      columnSize: get(options, 'end_finish.columnSize'),
    },
    range: {
      title: 'Lengths',
      disableSelectionOnRange: true,
      customValues: true,
      hidden: hiddenFields.range,
      operators: options.disableRangeFields ? undefined : operators,
      operator: options.defaultRange ? operators[1] : undefined,
      multiple: get(options, 'range.multiple'),
      templates: ['OCTG'],
      disabled: disabledByDefault(
        ['OCTG', 'Coupling', 'Shell'],
        serverValues,
        localSeed,
        !options.disableRangeFields,
        {
          enableOverride: get(options, 'range.enabled'),
          disableOverride: get(options, 'range.disabled'),
        }
      ),
      validation: (args) =>
        baseValidation({
          ...args,
          validationOptions: { isString: true },
        }),
      items: map(range, ({ name, id }) => ({
        value: id,
        label: name,
      })),
      columnSize: get(options, 'range.columnSize'),
      columnStyle: get(options, 'range.columnStyle'),
    },
    lengths_exact: {
      title: 'Lengths',
      uom: 'IN',
      templates: ['Mechanical'],
      disabled: disabledByDefault(
        ['Mechanical'],
        serverValues,
        localSeed,
        true,
        {
          enableOverride: get(options, 'lengths_exact.enabled'),
        }
      ),
      inputType: 'number',
      validation: baseValidation,
      columnSize: get(options, 'lengths_exact.columnSize'),
    },
    quantity: {
      title: 'Quantity',
      inputType: 'number',
      hidden: hiddenFields.quantity, // hide for public inquire.
      operatorIsValue: true,
      validation: baseValidation,
      operator: hiddenFields.quantity
        ? {}
        : {
            field: 'quantity_uom',
            label: get(getScalarUOM(_metadata), '[0].name'),
            value: get(getScalarUOM(_metadata), '[0].id'),
          },

      operators: map(getScalarUOM(_metadata), ({ name, id }) => ({
        field: 'quantity_uom',
        label: name,
        value: id,
      })),
      filterOperators: (currentFilters, _isMfg) => {
        const currentLabel = get(currentFilters, 'category.value.label');
        return map(
          includes(unitLabels, currentLabel) || _isMfg
            ? getUnitUOM(_metadata)
            : getScalarUOM(_metadata),
          ({ name, id }) => ({
            field: 'quantity_uom',
            label: name,
            value: id,
          })
        );
      },
      columnSize: get(options, 'quantity.columnSize'),
    },
    brand: {
      title: 'Brand',
      customValues: true,
      templates: ['OCTG', 'Mechanical', 'Coupling'],
      optionField: true, // we do not set brand on rfq submission
      multiple: get(options, 'brand.multiple'),
      disabled: !get(options, 'brand.enabled'),
      validation: baseValidation,
      items: compact(
        map(
          brand,
          ({
            name,
            name_long: nameLong,
            id,
            external_brand: externalBrand,
          }) => {
            if (
              !get(options, 'brand.external') ||
              (nameLong && externalBrand)
            ) {
              return {
                value: id,
                label: name,
              };
            }
            return undefined;
          }
        )
      ),
      columnSize: get(options, 'brand.columnSize'),
    },
  };

  if (serverValues || localSeed) {
    config = reverseItemFilters(config, serverValues, localSeed); // generate values from server seed
  }
  if (finishedSizeState || disableRange) {
    config = toggleRangeSelection(config, {
      allowRange: disableRange ? false : !finishedSizeState,
    });
    config.alloy.multiple = false;
    config.end_finish.multiple = false;
  }

  return config;
}

export function valueExtractor(value) {
  let _value;
  // DUPLCATE FUNCTION IN VALIDATION_ITEMS
  const min = get(value, 'min.value');
  const max = get(value, 'max.value');
  if (max || min) {
    _value = '';
  } else if (has(value, 'value')) {
    _value = value.value;
  } else {
    _value = value || '';
  }
  _value =
    isObject(_value) && !isArray(_value)
      ? omit(_value, ['min', 'max'])
      : _value;
  _value =
    isObject(_value) && !isArray(_value) && isEmpty(_value)
      ? undefined
      : _value;
  const totalValues = pickBy(
    {
      min,
      max,
      value: _value,
    },
    identity
  );
  return totalValues;
}

export function generateFilterValues(items, isMfg) {
  let values = map(items, (item, key) => {
    // Generate value for #/FT even though the field is disabled in OFS
    if (item.disabled && !(isMfg && key === 'weight_per_foot')) {
      return {};
    }
    const { min, max, value } = valueExtractor(item.value);
    let _value = value;

    if (item.multiple && key === 'category') {
      // criteria based search
      // when using inputs as a filter
      _value = map(value, (v) => v.value || '');
    }

    if (key === 'alloy') {
      const groups = get(item, 'selectedFilters.groups', []);

      return {
        metallurgy_primary: find(item?.value, 'primary_alloy')?.value,
        metallurgy_other: map(filter(_value, 'customValue'), 'value'),
        metallurgy: map(reject(_value, 'customValue'), 'value'),
        metallurgy_group: map(groups, 'value'),
      };
    }

    // Without this, we don't properly extract the value into an array like the other filters
    if (key === 'metallurgy_group') {
      return {
        metallurgy_group: map(_value, 'value'),
      };
    }

    if (key === 'quantity') {
      return {
        quantity_uom: get(item, 'operator.value'),
        quantity: _value,
      };
    }

    if (key === 'end_finish') {
      return {
        end_finish_other: map(filter(_value, 'customValue'), 'value'),
        end_finish: map(reject(_value, 'customValue'), 'value'),
        end_finish_category: map(
          filter(item.selectedFilters, ['type', 'end_finish_category']),
          'value'
        ),
        end_finish_thread_type: map(
          filter(item.selectedFilters, ['type', 'end_finish_thread_type']),
          'value'
        ),
        end_finish_licensee: map(
          filter(item.selectedFilters, ['type', 'end_finish_licensee']),
          'value'
        ),
      };
    }

    if (item.multiple && key === 'brand') {
      // criteria based search
      _value = map(value, (v) => v.value || '');
    }

    if (key === 'range') {
      if (get(item, 'multiple')) {
        // criteria based search
        return {
          range: map(_value, (v) => v.value || ''),
        };
      }
      if (get(item, 'value.customValue')) {
        return {
          [`lengths_exact`]: _value,
          [`lengths_min`]: null,
          [`lengths_max`]: null,
          [`range`]: null,
        };
      }
      return {
        [`lengths_min`]: min || null,
        [`lengths_max`]: max || null,
        [`range`]: min && max ? null : _value,
      };
    }

    // DEFAULT
    return {
      [`${key}_min`]: min,
      [`${key}_max`]: max,
      [key]: _value,
    };
  });

  values = reduce(
    values,
    (accum, v) => {
      const _accum = { ...accum, ...v };
      return _accum;
    },
    {}
  );

  // TODO - set undefined values to null
  return reduce(
    values,
    (acc, v, k) => {
      acc[k] = v === undefined ? null : v;
      return acc;
    },
    {}
  );
}
