import { map, sortBy, filter, includes, slice } from 'lodash';
import tree from 'state';
import { baseValidation, onboardingReverser } from 'utility/dynamicFormHelpers';
import { variables } from 'theme/variables';
import { getRole } from 'utility/hasAccount';

const { media_md: mediaMd, spacing_md: spacingMd } = variables;

export function generateEmployee({ seedData } = {}) {
  const employeesList = tree
    .select(['employees', 'currentEmployees'])
    .get(['result', 'docs']);

  const permissionLevels = [
    { id: 'admin', level: 'Administrator' },
    { id: 'facilitator_executive', level: 'Executive' },
    { id: 'facilitator_manager', level: 'Manager' },
    { id: 'facilitator_operator', level: 'Operator' },
    { id: 'facilitator_partner', level: 'Partner' },
  ];
  const managers = sortBy(
    filter(employeesList, (employee) =>
      includes(employee.roles_ts, 'facilitator_manager')
    ),
    ['full_name_s']
  );
  const currentRole = getRole();
  let roles;
  if (includes(currentRole.value, 'admin')) {
    roles = permissionLevels;
  } else if (includes(currentRole.value, 'executive')) {
    roles = slice(permissionLevels, 2, 5);
  } else if (includes(currentRole.value, 'manager')) {
    roles = slice(permissionLevels, 3, 5);
  } else if (
    includes(currentRole.value, 'operator') ||
    includes(currentRole.value, 'partner')
  ) {
    roles = [];
  }

  let config = {
    employee: {
      fields: {
        first_name: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        last_name: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: '6',
            lg: '6',
            extraPadding: mediaMd,
          },
        },
        email: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          ui: {
            sm: 12,
            md: 6,
            extraPadding: mediaMd,
          },
        },
        role: {
          required: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          items: map(roles, ({ id, level }) => ({
            value: id,
            label: level,
          })),
          ui: {
            label: 'Creating a new Employee or Partner',
            title: 'Select Role',
            placeholder: 'Select Role for this user',
            condensed: true,
            sm: 12,
            md: 6,
            extraPadding: mediaMd,
            marginBottom: spacingMd,
          },
        },
        manager_id: {
          required: false,
          disabled: true,
          value: { value: '', label: '' },
          validation: baseValidation,
          items: map(managers, ({ pk_pi: id, full_name_s: fullName }) => ({
            value: id,
            label: fullName,
          })),
          ui: {
            title: 'Select A Manager',
            placeholder: 'Select a Manager for this Partner',
            condensed: true,
            sm: 12,
            md: 6,
            extraPadding: mediaMd,
            marginBottom: spacingMd,
          },
        },
      },
    },
  };

  if (seedData) {
    config = onboardingReverser(config, seedData);
    config.valid = true;
  } else {
    config.temp = true;
  }
  return config;
}

export default generateEmployee;
