import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

export async function fetchActivityLog(
  activity,
  { limit = 10, page = 1 } = {}
) {
  const messages = await fetchJson(
    `${BASE}/api/activity/${activity}/?limit=${limit}&page=${page}&sort=create_timestamp&direction=desc`,
    {
      method: 'GET',
    }
  );
  return messages;
}

export async function fetchActivityFeed(
  feedId,
  { page, limit, feedGroup } = {}
) {
  const query = new URLSearchParams();
  if (page) {
    query.append('page', page);
  }
  if (limit) {
    query.append('limit', limit);
  }
  if (feedGroup) {
    query.append('feed_group', feedGroup);
  }
  const url = `${BASE}/api/util/activity/?instance=${feedId}&${query.toString()}`;
  const messages = await fetchJson(url, {
    method: 'GET',
  });
  return messages;
}
