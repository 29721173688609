import 'airbnb-browser-shims';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { renderRoutes } from 'react-router-config';
import { Switch, withRouter } from 'react-router-dom';
import { get, intersection, replace, toLower, split, isString } from 'lodash';
import RefactoredDashboard from 'components/DashboardHeader/RefactoredDashboard/RefactoredDashboard';
import { LicenseManager } from 'ag-grid-enterprise';
import { canUseDOM } from 'exenv';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AG_GRID_KEY } from './constants';
import { webRefactorSwitch } from './utility/environment';
// DO NOT REMOVE - Importing this file executes its contents
// eslint-disable-next-line no-unused-vars
import FontAwesomeIconInit from './components/Icon/FontAwesomeIconInit';
import loadGlobalScrips from './utility/scriptInjector';
import { addClass, removeClass } from './utility/domUtils';
import Routes from './routes';
import ModalRoutes from './modalRoutes';
import ModalWrappers from './components/ModalWrappers/ModalWrappers';
import AppHeaderSidebar from './components/AppHeader/AppHeaderSidebar';
import PublicHeader from './components/AppHeader/PublicHeader';
import PublicFooter from './components/AppFooter/PublicFooter';
import AppFooter from './components/AppFooter/AppFooter';
import { AppContainer } from './App.styles';
import { setRoutedModalState } from './state/application/actions';
import { ConfirmEmailWrapper } from './routes/ConfirmEmail/ConfirmEmail';
import NotificationBanner from './components/NotificationBanner/NotificationBanner';
import isIe11 from './utility/isIe11';
import DefaultMeta from './utility/DefaultMeta';
import GlobalAppApiRequests from './utility/GlobalAppApiRequests';

import './app.css';
import 'react-phone-input-2/lib/style.css';

if (isString(AG_GRID_KEY)) {
  LicenseManager.setLicenseKey(AG_GRID_KEY);
}

const hiddenHeaderRoutes = [
  'page-confirm-email',
  'page-login',
  'page-find-inventory',
  'page-recover-password',
  'page-recover-password-recover',
  'page-register',
  'page-login-template',
  'page-account-created',
  'page-reset',
  'page-search-template',
  'page-compare-items',
];
const hiddenFooterRoutes = [
  'page-confirm-email',
  'page-login',
  'page-find-inventory',
  'page-recover-password',
  'page-recover-password-recover',
  'page-register',
  'page-login-template',
  'page-account-created',
  'page-reset',
  'page-search-template',
  'page-compare-items',
];
const sidebarRoutes = ['page-dashboard', 'page-settings'];
const publicRoutes = [
  'page-landing',
  'page-welcome-suppliers', // specifically for marketing campaign links
  'page-global-warehouse',
  'page-product-guide',
  'page-c', // rename for alloy-guide
  'page-technical-resources',
  'page-buyers',
  'page-sellers',
  'page-technical-content',
  'page-industry-news',
  'page-company-news',
  'page-articles',
  'page-video',
  'page-audio',
  'page-why-pipesearch',
  'page-l', // listing view
  'page-l2', // listings V2 view
  'page-p', // product view

  'page-blog', // directs to content page
];

function generatePageClass(path) {
  return path === '/'
    ? 'page-landing'
    : toLower(replace(path, /\//g, ' page-'));
}

function determineOverlayModal() {
  let numberOfVisits = localStorage.getItem('visitTrue');
  numberOfVisits = numberOfVisits ? Number(numberOfVisits) : 0;
  try {
    localStorage.setItem('visitTrue', numberOfVisits + 1);
  } catch (err) {
    console.log(err);
  }
}

function TanstackQueryProvider({ children }) {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* 👇 for debugging Tanstack Query 👇 */}
      {false && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

TanstackQueryProvider.propTypes = {
  children: PropTypes.node,
};

function App({ history, location }) {
  const query = get(history, 'location.query');
  const key = get(location, 'query.key');
  const path = get(location, 'pathname');
  const confirmation = get(location, 'query.confirmation');

  useEffect(() => {
    loadGlobalScrips();
  }, []);

  const pageClasses = generatePageClass(path);
  const hiddenHeader = intersection(
    hiddenHeaderRoutes,
    split(pageClasses, ' ')
  ).length;
  const sidebarHeader = intersection(
    sidebarRoutes,
    split(pageClasses, ' ')
  ).length;
  const isPublicRoute = intersection(
    publicRoutes,
    split(pageClasses, ' ')
  ).length;
  const isBetaRoute = intersection(
    ['page-beta-announcement'],
    split(pageClasses, ' ')
  ).length;
  const isConfirmEmailRoute = intersection(
    ['page-confirm-email'],
    split(pageClasses, ' ')
  ).length;
  const hiddenFooter =
    intersection(hiddenFooterRoutes, split(pageClasses, ' ')).length ||
    !isPublicRoute;

  useEffect(() => {
    determineOverlayModal(isPublicRoute, query);
  }, [isPublicRoute, query]);

  const modalString = get(path.match(/(\/m\/)|(\/m$)/), '[0]');
  const overflowHidden = !!modalString;

  const renderHeaders = () => {
    if (!hiddenHeader && sidebarHeader) {
      return <AppHeaderSidebar />;
    }
    if (!hiddenHeader && (isPublicRoute || isBetaRoute)) {
      return webRefactorSwitch ? (
        <RefactoredDashboard location={location} />
      ) : (
        <PublicHeader />
      );
    }
    if (!hiddenHeader) {
      return <div />;
    }
    return null;
  };

  const renderFooters = () => {
    if (!hiddenFooter && (isPublicRoute || isBetaRoute)) {
      return <PublicFooter />;
    }
    if (!hiddenFooter) {
      return <AppFooter />;
    }
    return null;
  };

  // Remove scroll watcher if not on the Landing Page
  if (canUseDOM && window && window.location.pathname !== '/landing') {
    window.onscroll = null;
  }

  return (
    <TanstackQueryProvider>
      <div
        className={`App ${pageClasses} ${
          overflowHidden ? 'main-overflow-hidden' : ''
        } ${isIe11() ? 'ie-eleven' : ''}`}
      >
        <GlobalAppApiRequests />
        <DefaultMeta />
        <NotificationBanner />
        {confirmation && !isConfirmEmailRoute ? (
          <ConfirmEmailWrapper banner key={key} confirmation={confirmation} />
        ) : null}
        {renderHeaders()}

        <div className="transition-container">
          <AppContainer
            className={
              isPublicRoute ? 'public-app-container' : 'dashboard-app-container'
            }
            hiddenFooter={hiddenFooter}
            hiddenHeader={hiddenHeader}
            publicHeader={isPublicRoute}
          >
            <AppRoutes
              appRoutes={Routes}
              location={location}
              modalString={modalString}
            />
            <RoutedModalWrapper location={location} modalString={modalString} />
          </AppContainer>
          {renderFooters()}
        </div>

        <ModalWrappers />
      </div>
    </TanstackQueryProvider>
  );
}

App.propTypes = {
  // External objects
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

export function RoutedModalWrapper({ modalString, location }) {
  const [render, setRender] = useState(false);
  useEffect(() => {
    setRender(true);
  }, []);

  useEffect(() => {
    if (render) {
      if (modalString) {
        addClass(document.getElementsByTagName('html')[0], 'overflow-hidden');
      } else {
        removeClass(
          document.getElementsByTagName('html')[0],
          'overflow-hidden'
        );
      }
    }
  }, [modalString, render]);

  return !render ? null : (
    <TransitionGroup>
      <CSSTransition
        in
        onEntered={(element, transitioning) =>
          setRoutedModalState('entered', { element, transitioning })
        }
        onExited={(element, transitioning) =>
          setRoutedModalState('exited', { element, transitioning })
        }
        onExit={(element, transitioning) =>
          setRoutedModalState('exit', { element, transitioning })
        }
        onEnter={(element, transitioning) =>
          setRoutedModalState('enter', { element, transitioning })
        }
        unmountOnExit
        appear
        key={`${!!modalString}` || `m`}
        timeout={modalString ? 1200 : 600}
        classNames="routed-modal"
      >
        <Switch location={location}>{renderRoutes(ModalRoutes)}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

RoutedModalWrapper.propTypes = {
  modalString: PropTypes.string,
  // External object
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

export function AppRoutesWrapper({ appRoutes, location }) {
  return <Switch location={location}>{renderRoutes(appRoutes)}</Switch>;
}

AppRoutesWrapper.propTypes = {
  appRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
      ]),
      exact: PropTypes.bool,
      component: PropTypes.func,
      loadData: PropTypes.func,
    })
  ),
  // External object
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
};

const AppRoutes = React.memo(AppRoutesWrapper, (pp, np) => np.modalString);

export const exportedForTesting = {
  generatePageClass,
};

export default withRouter(App);
