import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

// LINT OVERRIDE #4
// More exports will be added (this override can be removed at that time)
// eslint-disable-next-line import/prefer-default-export
export async function getEmployees({
  sorting = {},
  filters = {},
  pagination = {},
}) {
  const body = {
    ...filters,
    ...pagination,
    ...sorting,
    filters: {
      ...filters.filters,
      agGridData: { filterModel: {}, startRow: 0, endRow: 500 },
    },
    only_facilitators: true,
    exclude_admins: false,
  };
  const response = await fetchJson(`${BASE}/api/user/list/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function updateEmployeeRole(employeeInfo, role) {
  const body = {
    ...employeeInfo,
    role,
  };
  const response = await fetchJson(`${BASE}/api/employee/${employeeInfo.id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
  return response;
}

export async function addEmployeeRole(employeeInfo) {
  const body = {
    ...employeeInfo,
  };
  const response = await fetchJson(`${BASE}/api/employee/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}
