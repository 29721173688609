import React from 'react';
import Loadable from 'react-loadable';
import { includes, noop } from 'lodash';

import Spinner from 'components/Spinner/Spinner';
import { isFacilitator, isPartner } from 'utility/hasAccount';
import {
  getRfqMeta,
  getActiveRfq,
  resetActiveRfq,
  getRfqCustomer,
} from 'state/requestForQuote/actions';
import {
  saveIngestedUsers,
  clearSearchData,
} from 'state/customers/prospecting/actions';
import { clearSearchCriteriaFormData } from 'state/customers/prospecting/searchCriteria/actions_searchCriteria';
import { getRfqSimpleInquiry } from 'state/requestForQuote/actions_simpleInquiry';
import { getSupplyCustomer } from 'state/sellersApplication/actions';
import { getSupplySimpleInquiry } from 'state/sellersApplication/actions_simpleinquiry';
import { getOnboardingMeta } from 'state/onboarding/actions';
import {
  getMyOpportunities,
  getMyMfgOpportunities,
} from 'state/opportunities/actions';
import { getListingDetails } from 'state/listings/actions';
import { getSupplyInterestsGroupDetails } from 'state/supplyInterests/actions_groups';
import { setValues, getValues } from 'utility/urlUtils';
import RoutedModal from './routes/RoutedModal/RoutedModal';
import {
  requireFacilitatorOrAdmin,
  requireAuth,
  requireAuthWithoutRole,
  AsyncPageError,
  redirectFacilitator,
} from './routes';

function PageLoader() {
  return (
    <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <Spinner />
    </div>
  );
}

// SELLERS FLOW
const AsyncSubmitSellersApplication = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersApplication" */ './routes/SubmitSellersApplication/SubmitSellersApplication'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersApplication'],
});

const AsyncSubmitSellersAddItem = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersApplication" */ './routes/SubmitSellersApplication/routes/SellersAddItem/SellersAddItem'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersApplication'],
});

const AsyncSubmitSellersPersonnel = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersPersonnel" */ './routes/SubmitSellersApplication/routes/SellersPersonnel/SellersPersonnel'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersPersonnel'],
});

const AsyncSubmitSellersSelectCustomer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersApplication" */ './routes/SubmitSellersApplication/routes/SellersSelectCustomer/SellersSelectCustomer'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersApplication'],
});

const AsyncSubmitSellersSelectCampaign = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersApplication" */ './routes/SubmitSellersApplication/routes/SellersSelectCampaign/SellersSelectCampaign.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersApplication'],
});

const AsyncSubmitPartnerSellersSelectCustomer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersApplication" */ './routes/SubmitSellersApplication/routes/SellersSelectCustomer/PartnerSellersSelectCustomer'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersApplication'],
});

const AsyncSellersItemGroupDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersApplication" */ './routes/SubmitSellersApplication/routes/SellersItemGroupDetails/SellersItemGroupDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersApplication'],
});

// BUYERS FLOW

const AsyncWishlistProductToRfq = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqFromWishlist/RfqTransitionAddItem'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncWishlistProductToRfqAddItem = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqFromWishlist/RfqTransitionAddItem'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRequestForQuoteV2 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/SubmitRequestForQuoteV2'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncRfqProcessOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqProcessOverview/RfqProcessOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqAccount = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqAccount/RfqAccount'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqSelectCustomer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqSelectCustomer/RfqSelectCustomer'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqSelectCampaign = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqSelectCampaign/RfqSelectCampaign.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqAddItem = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqAddItem/RfqAddItem'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqInformation = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqInformation/RfqInformation'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqPersonnel = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqPersonnel/RfqPersonnel'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqDetails/RfqDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqItemOverrides = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqItemOverrides/RfqItemOverrides'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncSubmitRfqReviewAndSubmit = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestForQuoteV2" */ './routes/SubmitRequestForQuoteV2/routes/RfqReviewAndSubmit/RfqReviewAndSubmit'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteV2'],
});

const AsyncPageAddNewListings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/AddNewListingRoute/AddNewListingRoute'
    ),
  loading: () => <PageLoader />,
  modules: ['AsyncPageAddNewListings'],
});

const AsyncPageWaitlistWishlist = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "WaitlistWishlistModal" */ './routes/WaitlistWishlist/WaitlistWishlistModal'
    ),
  loading: () => <PageLoader />,
  modules: ['WaitlistWishlistModal'],
});

const AsyncPageFacilitatorOpportunitiesMap = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "FacilitatorOpportunitiesMap" */ './routes/Opportunities/FacilitatorOpportunitiesMap'
    ),
  loading: () => <PageLoader />,
  modules: ['FacilitatorOpportunitiesMap'],
});

const AsyncRfqDetailsAssessmentSelectInventory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsAssessment/routes/RfqDetailsAssementSelectInventory/RfqDetailsAssessmentSelectInventory'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsAssessmentSelectInventory '],
});

const AsyncMfgRfqDetailsQuoteItemConfiguration = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsQuote/routes/MfgRfqDetailsQuoteItemConfiguration/MfgRfqDetailsQuoteItemConfiguration'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsQuoteItemConfiguration'],
});

const AsyncPageNewCampaign = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/AddNewCampaignRoute'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

const AsyncPageNewCampaignSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/routes/NewCampaignSettings/NewCampaignSettings'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

const AsyncPageNewCampaignPreview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/routes/NewCampaignPreview/NewCampaignPreview'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

const AsyncPageNewCampaignSchedule = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/routes/NewCampaignSchedule/NewCampaignSchedule'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

const AsyncPageNewCampaignAttachment = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/routes/NewCampaignAttachment/NewCampaignAttachment'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

const AsyncPageNewCampaignReview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/routes/NewCampaignReview/NewCampaignReview'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

const AsyncPageNewCampaignRecipients = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/routes/NewCampaignRecipients/NewCampaignRecipients.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

// NAV
const AsyncPageNewCampaignNav = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewCampaignRoute" */ './routes/AddNewCampaignRoute/AddNewCampaignNav'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewCampaignRoute'],
});

const AsyncPageNewOrder = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewOrderRoute/AddNewOrderRoute'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewOrderRoute'],
});

const AsyncPageNewOrderPurchaseOrder = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewOrderRoute/routes/NewOrderPurchaseOrder/NewOrderPurchaseOrder'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewOrderRoute'],
});

const AsyncPageNewOrderSelectItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewOrderRoute/routes/NewOrderSelectItems/NewOrderSelectItems'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewOrderRoute'],
});

const AsyncPageNewOrderModifyItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewOrderRoute/routes/NewOrderModifyItems/NewOrderModifyItems'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewOrderRoute'],
});

const AsyncPageNewOrderTermsPricing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewOrderRoute/routes/NewOrderTermsPricing/NewOrderTermsPricing'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewOrderRoute'],
});

// NAV
const AsyncPageNewOrderNav = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewOrderRoute/AddNewOrderNav'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewOrderRoute'],
});

const AsyncPageNewManufacturingOrder = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewManufacturingOrderRoute" */ './routes/AddNewManufacturingOrderRoute/AddNewOrderRoute.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewManufacturingOrderRoute'],
});

const AsyncPageNewManufacturingOrderDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewManufacturingOrderRoute" */ './routes/AddNewManufacturingOrderRoute/routes/NewOrderDocuments/NewOrderDocuments.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewManufacturingOrderRoute'],
});

const AsyncPageNewManufacturingOrderSelectItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewManufacturingOrderRoute" */ './routes/AddNewManufacturingOrderRoute/routes/NewOrderSelectItems/NewOrderSelectItems.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewManufacturingOrderRoute'],
});

const AsyncPageNewManufacturingOrderModifyItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewManufacturingOrderRoute" */ './routes/AddNewManufacturingOrderRoute/routes/NewOrderModifyItems/NewOrderModifyItems.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewManufacturingOrderRoute'],
});

const AsyncPageNewManufacturingOrderTermsPricing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewManufacturingOrderRoute" */ './routes/AddNewManufacturingOrderRoute/routes/NewOrderTermsPricing/NewOrderTermsPricing.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewManufacturingOrderRoute'],
});

// NAV
const AsyncPageNewManufacturingOrderNav = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewManufacturingOrderRoute" */ './routes/AddNewManufacturingOrderRoute/AddNewOrderNav.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewManufacturingOrderRoute'],
});

const AsyncPageNewProspects = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewProspectsRoute/AddNewProspectsRoute'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewProspectsRoute'],
});

const AsyncPageNewProspectsTermsConditions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewProspectsRoute/routes/NewProspectsTermsConditions/NewProspectsTermsConditions'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewProspectsRoute'],
});

const AsyncPageNewProspectsSearchCriteria = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewProspectsRoute/routes/NewProspectsSearchCriteria/NewProspectsSearchCriteria'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewProspectsRoute'],
});

const AsyncPageNewProspectsProspects = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewProspectsRoute/routes/NewProspectsProspects/NewProspectsProspects'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewProspectsRoute'],
});

const AsyncPageNewProspectsSaveEnrich = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewProspectsRoute/routes/NewProspectsSaveEnrich/NewProspectsSaveEnrich'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewProspectsRoute'],
});

// NAV
const AsyncPageNewProspectsNav = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewOrderRoute" */ './routes/AddNewProspectsRoute/AddNewProspectsNav'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewProspectsRoute'],
});

const AsyncPageNewSupplyOrder = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewAddNewSupplyOrderRouteOrderRoute" */ './routes/AddNewSupplyOrderRoute/AddNewSupplyOrderRoute'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewSupplyOrderRoute'],
});

const AsyncPageNewSupplyOrderDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewSupplyOrderRoute" */ './routes/AddNewSupplyOrderRoute/routes/NewSupplyOrderDocuments/NewSupplyOrderDocuments'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewSupplyOrderRoute'],
});

const AsyncPageNewSupplyOrderJointVentureBreakdown = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewSupplyOrderRoute" */ './routes/AddNewSupplyOrderRoute/routes/NewSupplyOrderJointVentureBreakdown/NewSupplyOrderJointVentureBreakdown'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewSupplyOrderRoute'],
});

const AsyncPageNewSupplyOrderSelectItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewSupplyOrderRoute" */ './routes/AddNewSupplyOrderRoute/routes/NewSupplyOrderSelectItems/NewSupplyOrderSelectItems'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewSupplyOrderRoute'],
});

const AsyncPageNewSupplyOrderModifyItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewSupplyOrderRoute" */ './routes/AddNewSupplyOrderRoute/routes/NewSupplyOrderModifyItems/NewSupplyOrderModifyItems'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewSupplyOrderRoute'],
});

const AsyncPageNewSupplyOrderTermsPricing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewSupplyOrderRoute" */ './routes/AddNewSupplyOrderRoute/routes/NewSupplyOrderTermsPricing/NewSupplyOrderTermsPricing'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewSupplyOrderRoute'],
});

// NAV
const AsyncPageNewSupplyOrderNav = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddNewSupplyOrderRoute" */ './routes/AddNewSupplyOrderRoute/AddNewSupplyOrderNav'
    ),
  loading: () => <PageLoader />,
  modules: ['AddNewSupplyOrderRoute'],
});

const AsyncSellersApplicationNav = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersApplication" */ './routes/SubmitSellersApplication/SubmitSellersApplicationNav'
    ),
  loading: () => <div />,
  modules: ['SellersApplication'],
});
// NAV

const campaignRoutes = [
  '/*/m/add-new-campaign/settings',
  '/*/m/add-new-campaign/:id/settings',
  '/*/m/add-new-campaign/:id/recipients',
  '/*/m/add-new-campaign/:id/recipients/customers',
  '/*/m/add-new-campaign/:id/preview',
  '/*/m/add-new-campaign/:id/review',
  '/*/m/add-new-campaign/:id/schedule',
  '/*/m/add-new-campaign/:id/attachment',
  '/*/m/add-new-campaign/:id',
];

const orderRoutes = [
  '/*/m/add-new-order/purchase-order',
  '/*/m/add-new-order/:orderId/purchase-order',
  '/*/m/add-new-order/:orderId/select-items',
  '/*/m/add-new-order/:orderId/modify-items',
  '/*/m/add-new-order/:orderId/terms-pricing',
  '/*/m/add-new-order/:orderId',
];

const manufacturingOrderRoutes = [
  '/*/m/add-new-manufacturing-order/documents',
  '/*/m/add-new-manufacturing-order/:orderId/documents',
  '/*/m/add-new-manufacturing-order/:orderId/select-items',
  '/*/m/add-new-manufacturing-order/:orderId/modify-items',
  '/*/m/add-new-manufacturing-order/:orderId/terms-pricing',
  '/*/m/add-new-manufacturing-order/:orderId',
];

const prospectsRoutes = [
  '/*/m/add-new-prospects/terms-conditions',
  '/*/m/add-new-prospects/terms-conditions',
  '/*/m/add-new-prospects/search-criteria',
  '/*/m/add-new-prospects/prospects',
  '/*/m/add-new-prospects/save-enrich',
];

const supplyOrderRoutes = [
  '/*/m/add-new-supply-order/documents/purchase',
  '/*/m/add-new-supply-order/documents/consignment',
  '/*/m/add-new-supply-order/documents/exclusive-marketing',
  '/*/m/add-new-supply-order/documents/joint-venture',
  '/*/m/add-new-supply-order/joint-venture-breakdown/joint-venture',
  '/*/m/add-new-supply-order/:orderId/joint-venture-breakdown/joint-venture',
  '/*/m/add-new-supply-order/:orderId/documents/purchase',
  '/*/m/add-new-supply-order/:orderId/documents/consignment',
  '/*/m/add-new-supply-order/:orderId/documents/exclusive-marketing',
  '/*/m/add-new-supply-order/:orderId/documents/joint-venture',
  '/*/m/add-new-supply-order/:orderId/select-items/purchase',
  '/*/m/add-new-supply-order/:orderId/select-items/consignment',
  '/*/m/add-new-supply-order/:orderId/select-items/exclusive-marketing',
  '/*/m/add-new-supply-order/:orderId/select-items/joint-venture',
  '/*/m/add-new-supply-order/:orderId/modify-items/purchase',
  '/*/m/add-new-supply-order/:orderId/modify-items/consignment',
  '/*/m/add-new-supply-order/:orderId/modify-items/exclusive-marketing',
  '/*/m/add-new-supply-order/:orderId/modify-items/joint-venture',
  '/*/m/add-new-supply-order/:orderId/terms-pricing/purchase',
  '/*/m/add-new-supply-order/:orderId/terms-pricing/consignment',
  '/*/m/add-new-supply-order/:orderId/terms-pricing/exclusive-marketing',
  '/*/m/add-new-supply-order/:orderId/terms-pricing/joint-venture',
  '/*/m/add-new-supply-order/:orderId',
];

const Routes = [
  {
    path: campaignRoutes,
    component: RoutedModal,
    unmountFunc: () => {
      saveIngestedUsers(null);
    },
    confirmClose: true,
    routes: [
      {
        path: campaignRoutes,
        component: requireAuthWithoutRole(AsyncPageNewCampaign),
        exact: true,
        data: {
          navComponent: AsyncPageNewCampaignNav,
          logo: true,
          white: true,
          done: true,
        },
        routes: [
          {
            path: [
              '/*/m/add-new-campaign/settings',
              '/*/m/add-new-campaign/:id/settings',
            ],
            component: AsyncPageNewCampaignSettings,
            exact: true,
            data: { name: 'Settings' },
          },
          {
            path: [
              '/*/m/add-new-campaign/:id/recipients',
              '/*/m/add-new-campaign/:id/recipients/customers',
            ],
            component: AsyncPageNewCampaignRecipients,
            exact: true,
            data: { name: 'Recipients' },
          },
          {
            path: ['/*/m/add-new-campaign/:id/preview'],
            component: AsyncPageNewCampaignPreview,
            exact: true,
            data: { name: 'Preview' },
          },
          {
            path: ['/*/m/add-new-campaign/:id/schedule'],
            component: AsyncPageNewCampaignSchedule,
            exact: true,
            data: { name: 'Schedule' },
          },
          {
            path: ['/*/m/add-new-campaign/:id/attachment'],
            component: AsyncPageNewCampaignAttachment,
            exact: true,
            data: { name: 'Attachment' },
          },
          {
            path: ['/*/m/add-new-campaign/:id/review'],
            component: AsyncPageNewCampaignReview,
            exact: true,
            data: { name: 'Review' },
          },
        ],
      },
    ],
  },
  {
    path: orderRoutes,
    component: RoutedModal,
    confirmClose: true,
    routes: [
      {
        path: orderRoutes,
        component: requireAuthWithoutRole(AsyncPageNewOrder),
        exact: true,
        data: {
          navComponent: AsyncPageNewOrderNav,
          logo: true,
          white: true,
          done: true,
        },
        routes: [
          {
            path: [
              '/*/m/add-new-order/purchase-order',
              '/*/m/add-new-order/:orderId/purchase-order',
            ],
            component: AsyncPageNewOrderPurchaseOrder,
            exact: true,
            data: { name: 'Purchase Order' },
          },
          {
            path: [
              '/*/m/add-new-order/select-items',
              '/*/m/add-new-order/:orderId/select-items',
            ],
            component: AsyncPageNewOrderSelectItems,
            exact: true,
            data: { name: 'Select Items' },
          },
          {
            path: [
              '/*/m/add-new-order/modify-items',
              '/*/m/add-new-order/:orderId/modify-items',
            ],
            component: AsyncPageNewOrderModifyItems,
            exact: true,
            data: { name: 'Modify Items' },
          },
          {
            path: [
              '/*/m/add-new-order/terms-pricing',
              '/*/m/add-new-order/:orderId/terms-pricing',
            ],
            component: AsyncPageNewOrderTermsPricing,
            exact: true,
            data: { name: 'Terms & Pricing' },
          },
        ],
      },
    ],
  },
  {
    path: manufacturingOrderRoutes,
    component: RoutedModal,
    confirmClose: true,
    routes: [
      {
        path: manufacturingOrderRoutes,
        component: requireAuthWithoutRole(AsyncPageNewManufacturingOrder),
        exact: true,
        data: {
          navComponent: AsyncPageNewManufacturingOrderNav,
          logo: true,
          white: true,
          done: true,
        },
        routes: [
          {
            path: [
              '/*/m/add-new-manufacturing-order/documents',
              '/*/m/add-new-manufacturing-order/:orderId/documents',
            ],
            component: AsyncPageNewManufacturingOrderDocuments,
            exact: true,
            data: { name: 'Documents & Files' },
          },
          {
            path: [
              '/*/m/add-new-manufacturing-order/select-items',
              '/*/m/add-new-manufacturing-order/:orderId/select-items',
            ],
            component: AsyncPageNewManufacturingOrderSelectItems,
            exact: true,
            data: { name: 'Select Items' },
          },
          {
            path: [
              '/*/m/add-new-manufacturing-order/modify-items',
              '/*/m/add-new-manufacturing-order/:orderId/modify-items',
            ],
            component: AsyncPageNewManufacturingOrderModifyItems,
            exact: true,
            data: { name: 'Modify Items' },
          },
          {
            path: [
              '/*/m/add-new-manufacturing-order/terms-pricing',
              '/*/m/add-new-manufacturing-order/:orderId/terms-pricing',
            ],
            component: AsyncPageNewManufacturingOrderTermsPricing,
            exact: true,
            data: { name: 'Terms & Pricing' },
          },
        ],
      },
    ],
  },
  {
    path: prospectsRoutes,
    component: RoutedModal,
    confirmClose: true,
    unmountFunc: () => {
      clearSearchData();
      clearSearchCriteriaFormData();
    },
    routes: [
      {
        path: prospectsRoutes,
        component: requireAuthWithoutRole(AsyncPageNewProspects),
        exact: true,
        data: {
          navComponent: AsyncPageNewProspectsNav,
          logo: true,
          white: true,
          done: true,
        },
        routes: [
          {
            path: ['/*/m/add-new-prospects/terms-conditions'],
            component: AsyncPageNewProspectsTermsConditions,
            exact: true,
            data: { name: 'Terms & Conditions' },
          },
          {
            path: ['/*/m/add-new-prospects/search-criteria'],
            component: AsyncPageNewProspectsSearchCriteria,
            exact: true,
            data: { name: 'Search Criteria' },
          },
          {
            path: ['/*/m/add-new-prospects/prospects'],
            component: AsyncPageNewProspectsProspects,
            exact: true,
            data: { name: 'Prospects' },
          },
          {
            path: ['/*/m/add-new-prospects/save-enrich'],
            component: AsyncPageNewProspectsSaveEnrich,
            exact: true,
            data: { name: 'Save & Enrich' },
          },
        ],
      },
    ],
  },
  {
    path: supplyOrderRoutes,
    component: RoutedModal,
    confirmClose: true,
    routes: [
      {
        path: supplyOrderRoutes,
        component: requireAuthWithoutRole(AsyncPageNewSupplyOrder),
        exact: true,
        data: {
          navComponent: AsyncPageNewSupplyOrderNav,
          logo: true,
          white: true,
          done: true,
        },
        routes: [
          {
            path: [
              '/*/m/add-new-supply-order/documents/purchase',
              '/*/m/add-new-supply-order/documents/consignment',
              '/*/m/add-new-supply-order/documents/exclusive-marketing',
              '/*/m/add-new-supply-order/documents/joint-venture',
              '/*/m/add-new-supply-order/:orderId/documents/purchase',
              '/*/m/add-new-supply-order/:orderId/documents/consignment',
              '/*/m/add-new-supply-order/:orderId/documents/exclusive-marketing',
              '/*/m/add-new-supply-order/:orderId/documents/joint-venture',
            ],
            component: AsyncPageNewSupplyOrderDocuments,
            exact: true,
            data: { name: 'Documents & Files' },
          },
          {
            path: [
              '/*/m/add-new-supply-order/joint-venture-breakdown/joint-venture',
              '/*/m/add-new-supply-order/:orderId/joint-venture-breakdown/joint-venture',
            ],
            component: AsyncPageNewSupplyOrderJointVentureBreakdown,
            exact: true,
            data: { name: 'Joint Venture Breakdown' },
          },
          {
            path: [
              '/*/m/add-new-supply-order/select-items/purchase',
              '/*/m/add-new-supply-order/select-items/consignment',
              '/*/m/add-new-supply-order/select-items/exclusive-marketing',
              '/*/m/add-new-supply-order/select-items/joint-venture',
              '/*/m/add-new-supply-order/:orderId/select-items/purchase',
              '/*/m/add-new-supply-order/:orderId/select-items/consignment',
              '/*/m/add-new-supply-order/:orderId/select-items/exclusive-marketing',
              '/*/m/add-new-supply-order/:orderId/select-items/joint-venture',
            ],
            component: AsyncPageNewSupplyOrderSelectItems,
            exact: true,
            data: { name: 'Select & Confirm' },
          },
          {
            path: [
              '/*/m/add-new-supply-order/modify-items/purchase',
              '/*/m/add-new-supply-order/modify-items/consignment',
              '/*/m/add-new-supply-order/modify-items/exclusive-marketing',
              '/*/m/add-new-supply-order/modify-items/joint-venture',
              '/*/m/add-new-supply-order/:orderId/modify-items/purchase',
              '/*/m/add-new-supply-order/:orderId/modify-items/consignment',
              '/*/m/add-new-supply-order/:orderId/modify-items/exclusive-marketing',
              '/*/m/add-new-supply-order/:orderId/modify-items/joint-venture',
            ],
            component: AsyncPageNewSupplyOrderModifyItems,
            exact: true,
            data: { name: 'Modify Items' },
          },
          {
            path: [
              '/*/m/add-new-supply-order/terms-pricing/purchase',
              '/*/m/add-new-supply-order/terms-pricing/consignment',
              '/*/m/add-new-supply-order/terms-pricing/exclusive-marketing',
              '/*/m/add-new-supply-order/terms-pricing/joint-venture',
              '/*/m/add-new-supply-order/:orderId/terms-pricing/purchase',
              '/*/m/add-new-supply-order/:orderId/terms-pricing/consignment',
              '/*/m/add-new-supply-order/:orderId/terms-pricing/exclusive-marketing',
              '/*/m/add-new-supply-order/:orderId/terms-pricing/joint-venture',
            ],
            component: AsyncPageNewSupplyOrderTermsPricing,
            exact: true,
            data: { name: 'Terms & Pricing' },
          },
        ],
      },
    ],
  },
  {
    path: [
      '/dashboard/opportunities/:id/assessment/quote-items/m/rfq-item/:itemId',
      '/dashboard/opportunities/:id/assessment/quote-items/m/rfq-item/:itemId/quote-item/:quoteItemId',
    ],
    component: RoutedModal,
    routes: [
      {
        path: [
          '/dashboard/opportunities/:id/assessment/quote-items/m/rfq-item/:itemId',
          '/dashboard/opportunities/:id/assessment/quote-items/m/rfq-item/:itemId/quote-item/:quoteItemId',
        ],
        exact: true,
        component: requireFacilitatorOrAdmin(
          AsyncRfqDetailsAssessmentSelectInventory
        ),
        data: {
          logo: true,
          white: true,
          done: true,
        },
      },
    ],
  },
  {
    path: [
      '/dashboard/manufacturing-opportunities/:id/quote/items/m/rfq-item/',
      '/dashboard/manufacturing-opportunities/:id/quote/items/m/rfq-item/:itemId',
    ],
    component: RoutedModal,
    routes: [
      {
        path: [
          '/dashboard/manufacturing-opportunities/:id/quote/items/m/rfq-item/',
          '/dashboard/manufacturing-opportunities/:id/quote/items/m/rfq-item/:itemId',
        ],
        exact: true,
        component: requireFacilitatorOrAdmin(
          AsyncMfgRfqDetailsQuoteItemConfiguration
        ),
        data: {
          logo: true,
          white: true,
          done: true,
        },
      },
    ],
  },
  {
    path: ['/*/m/opportunity-map'],
    component: RoutedModal,
    routes: [
      {
        path: ['/*/m/opportunity-map'],
        component: requireAuthWithoutRole(AsyncPageFacilitatorOpportunitiesMap),
        exact: true,
        data: {
          logo: true,
        },
      },
    ],
  },

  {
    path: ['/*/m/add-new-listing', '/*/m/add-new-listing/:id'],
    component: RoutedModal,
    routes: [
      {
        path: ['/*/m/add-new-listing', '/*/m/add-new-listing/:id'],
        component: requireAuthWithoutRole(AsyncPageAddNewListings),
        exact: true,
        data: {
          logo: true,
        },
      },
    ],
    unmountFunc: (listingId) => {
      getListingDetails(listingId);
    },
  },

  {
    path: ['/*/m/my-wishlist/:itemId', '/*/m/my-wishlist'],
    component: RoutedModal,
    routes: [
      {
        path: ['/*/m/my-wishlist/:itemId', '/*/m/my-wishlist'],
        component: AsyncPageWaitlistWishlist,
        data: {
          logo: true,
          white: true,
          done: true,
        },
      },
    ],
  },

  {
    path: [
      '/*/m/supply/sellers-application',
      '/*/m/supply/supply-customer',
      '/*/m/supply/supply-campaign',
      '/*/m/supply/group',
      '/*/m/supply/add-item',
      '/*/m/supply/:group/personnel',
      '/*/m/supply/:group/supply-customer',
      '/*/m/supply/:group/supply-campaign',
      '/*/m/supply/:group/add-item/:id',
      '/*/m/supply/:group/add-item/',
      '/*/m/supply/:group/group',
      '/*/m/supply/partner-supply-customer',
      '/*/m/supply/:group/partner-supply-customer',
    ],
    component: RoutedModal,
    fetch: async (data = {}) => {
      getRfqMeta();

      let activeSupplyGroup;
      if (data.supplyGroupId) {
        activeSupplyGroup = await getSupplyInterestsGroupDetails(
          data.supplyGroupId
        );
        const querystring = getValues();
        if (
          isFacilitator() &&
          activeSupplyGroup?.user?.id &&
          !querystring.customer
        ) {
          querystring.customer = activeSupplyGroup.user.id;
          setValues(querystring);
        }
      }

      if (data.simplifiedInquiryId) {
        await getSupplySimpleInquiry(data.simplifiedInquiryId);
      }
      if (data.customerId) {
        await getSupplyCustomer(data.customerId);
      }
    },
    unmountFunc: () => {
      resetActiveRfq();
    },

    confirmClose: true,
    modalIntercept: (path) => {
      const hasSupplyId =
        (!includes(path, '/m/supply/partner-supply-customer') &&
          !includes(path, '/m/supply/group') &&
          !includes(path, '/m/supply/add-item')) ||
        includes(path, '/sellers-application/');
      const ignoreModal =
        isPartner() ||
        hasSupplyId ||
        includes(path, 'item-details') ||
        includes(path, 'item-files') ||
        includes(path, 'item-images') ||
        includes(path, 'review');
      return ignoreModal ? false : 'quickInquirySupply';
    },

    routes: [
      {
        path: [
          '/*/m/supply/sellers-application',
          '/*/m/supply/supply-customer',
          '/*/m/supply/supply-campaign',
          '/*/m/supply/group',
          '/*/m/supply/add-item',
          '/*/m/supply/:group/personnel',
          '/*/m/supply/:group/supply-customer',
          '/*/m/supply/:group/supply-campaign',
          '/*/m/supply/:group/add-item/:id',
          '/*/m/supply/:group/add-item/',
          '/*/m/supply/:group/group',
          '/*/m/supply/partner-supply-customer',
          '/*/m/supply/:group/partner-supply-customer',
        ],
        component: AsyncSubmitSellersApplication,
        data: {
          logo: true,
          white: true,
          navComponent: AsyncSellersApplicationNav,
        },

        routes: [
          {
            path: ['/*/m/supply/sellers-application'],
            component: AsyncSubmitSellersAddItem,
            exact: true,
            data: { name: 'Overview' },
          },
          {
            path: [
              '/*/m/supply/supply-customer',
              '/*/m/supply/:group/supply-customer',
            ],
            component: AsyncSubmitSellersSelectCustomer,
            exact: true,
            data: { name: 'Select Customer', facilitator: true },
          },
          {
            path: [
              '/*/m/supply/supply-campaign',
              '/*/m/supply/:group/supply-campaign',
            ],
            component: AsyncSubmitSellersSelectCampaign,
            exact: true,
            data: { name: 'Select Campaign', facilitator: true },
          },
          {
            path: [
              '/*/m/supply/partner-supply-customer',
              '/*/m/supply/:group/partner-supply-customer',
            ],
            component: AsyncSubmitPartnerSellersSelectCustomer,
            exact: true,
            data: { name: 'Select Customer', facilitator: true },
          },
          {
            path: ['/*/m/supply/:group/personnel'],
            component: AsyncSubmitSellersPersonnel,
            exact: true,
            data: { name: 'Select Customer', facilitator: true },
          },
          {
            path: [
              '/*/m/supply/add-item',
              '/*/m/supply/:group/add-item/:id',
              '/*/m/supply/:group/add-item/',
            ],
            component: AsyncSubmitSellersAddItem,
            exact: true,
            data: { name: 'Item Submission' },
          },
          {
            path: ['/*/m/supply/:group/group', '/*/m/supply/group'],
            component: AsyncSellersItemGroupDetails,
            exact: true,
            data: { name: 'Item Group' },
          },
          {
            component: AsyncSellersItemGroupDetails,
            data: { name: 'Item Group' },
          },
        ],
      },
    ],
  },

  {
    path: ['/*/m/rfq/:rfqId/*', '/*/m/rfq/*'],
    component: RoutedModal,
    fetch: async (data = {}, route = {}) => {
      getRfqMeta();

      const isMfg =
        !!route?.params?.[0] &&
        (includes(
          route.params[0],
          'dashboard/opportunities/manufacturing-rfqs'
        ) ||
          includes(route.params[0], 'dashboard/manufacturing-opportunities'));

      let activeRfq;
      if (data.rfqId) {
        activeRfq = await getActiveRfq(data.rfqId, isMfg);
        const querystring = getValues();
        if (isFacilitator() && activeRfq?.user && !querystring.customer) {
          querystring.customer = activeRfq.user;
          setValues(querystring);
        }
      }
      if (data.simplifiedInquiryId) {
        await getRfqSimpleInquiry(data.simplifiedInquiryId);
      }
      if (data.customerId && isFacilitator()) {
        await getRfqCustomer(data.customerId);
      }
      if (route.path && includes(route.path, 'onboarding')) {
        await getOnboardingMeta();
      }
    },
    unmountFunc: (currentPath, originQuerystring) => {
      resetActiveRfq();

      if (includes(currentPath, 'dashboard/opportunities/manufacturing-rfqs')) {
        getMyMfgOpportunities({ filters: originQuerystring });
      } else {
        getMyOpportunities({ filters: originQuerystring });
      }
    },
    confirmClose: true,
    modalIntercept: (path) => {
      const hasRfqId =
        includes(path, 'rfq') &&
        !includes(path, 'rfq/item-selection') &&
        !includes(path, 'rfq/overview') &&
        !includes(path, 'rfq/information') &&
        !includes(path, 'rfq/account');
      const ignoreModal =
        hasRfqId ||
        includes(path, 'overrides') ||
        includes(path, 'details') ||
        includes(path, 'review');
      return ignoreModal ? false : 'quickInqueryModalForReal';
    },
    routes: [
      {
        path: [
          '/*/m/rfq/overview',
          '/*/m/rfq/account',
          '/*/m/rfq/customer',
          '/*/m/rfq/:rfqId/customer',
          '/*/m/rfq/campaign',
          '/*/m/rfq/:rfqId/campaign',
          '/*/m/rfq/item-selection',
          '/*/m/rfq/:rfqId/item-selection',
          '/*/m/rfq/information',
          '/*/m/rfq/:rfqId/information',
          '/*/m/rfq/personnel',
          '/*/m/rfq/:rfqId/personnel',
          '/*/m/rfq/overrides',
          '/*/m/rfq/:rfqId/overrides',
          '/*/m/rfq/:rfqId/details',
          '/*/m/rfq/:rfqId/details',
          '/*/m/rfq/:rfqId/review',
          '/*/m/rfq/:rfqId/review',
          '/*/m/rfq/:rfqId/*',
          '/*/m/rfq/*',
        ],
        component: AsyncSubmitRequestForQuoteV2,
        data: {
          logo: true,
          white: true,
          title: (location) => {
            const isMfg =
              !!location.pathname &&
              (includes(
                location.pathname,
                'dashboard/opportunities/manufacturing-rfqs'
              ) ||
                includes(
                  location.pathname,
                  'dashboard/manufacturing-opportunities'
                ));
            if (isMfg) {
              return 'Manufacturing RFQ';
            }
            return 'OCTG RFQ';
          },
        },
        routes: [
          {
            path: ['/*/m/rfq/overview', '/*/m/rfq/:rfqId/overview'],
            component: AsyncRfqProcessOverview,
            exact: true,
            data: { name: 'Overview', mrfq: true },
          },
          {
            path: ['/*/m/rfq/account'],
            component: AsyncSubmitRfqAccount,
            exact: true,
            data: { name: 'Account', mrfq: true },
          },
          {
            path: ['/*/m/rfq/customer', '/*/m/rfq/:rfqId/customer'],
            component: requireAuth(AsyncSubmitRfqSelectCustomer),
            exact: true,
            data: { name: 'Select Customer', facilitator: true, mrfq: true },
          },
          {
            path: ['/*/m/rfq/campaign', '/*/m/rfq/:rfqId/campaign'],
            component: requireAuth(AsyncSubmitRfqSelectCampaign),
            exact: true,
            data: {
              name: 'Select Origin Campaign',
              facilitator: true,
              mrfq: true,
            },
          },
          {
            path: ['/*/m/rfq/item-selection', '/*/m/rfq/:rfqId/item-selection'],
            component: requireAuth(AsyncSubmitRfqAddItem),
            exact: true,
            data: { name: 'Add Items', productFlow: true, mrfq: true },
          },
          {
            path: ['/*/m/rfq/information', '/*/m/rfq/:rfqId/information'],
            component: requireAuth(AsyncSubmitRfqInformation),
            exact: true,
            data: { name: 'Project Details', productFlow: true, mrfq: true },
          },
          {
            path: ['/*/m/rfq/:rfqId/personnel', '/*/m/rfq/:rfqId/personnel'],
            component: requireAuth(AsyncSubmitRfqPersonnel),
            exact: true,
            data: { name: 'Personnel', facilitator: true, mrfq: true },
          },
          {
            path: ['/*/m/rfq/overrides', '/*/m/rfq/:rfqId/overrides'],
            component: requireAuth(AsyncSubmitRfqItemOverrides),
            exact: true,
            data: {
              name: 'Customize Timing*',
              facilitator: true,
              optional: true,
              mrfq: true,
            },
          },
          {
            path: ['/*/m/rfq/:rfqId/details', '/*/m/rfq/:rfqId/details'],
            component: requireAuth(AsyncSubmitRfqDetails),
            exact: true,
            data: {
              name: 'Well Information*',
              facilitator: true,
              optional: true,
            },
          },
          {
            path: ['/*/m/rfq/:rfqId/review', '/*/m/rfq/:rfqId/review'],
            component: requireAuth(AsyncSubmitRfqReviewAndSubmit),
            exact: true,
            data: { name: 'Review', productFlow: true, mrfq: true },
          },

          {
            component: AsyncPageError,
          },
        ],
      },
    ],
  },

  {
    path: ['/*/m/wishlist/*'],
    component: RoutedModal,
    fetch: async () => {
      noop();
    },
    unmountFunc: () => {
      resetActiveRfq();
    },
    onCloseOverride: '/',
    confirmClose: true,
    modalIntercept: () => {
      noop();
    },
    routes: [
      {
        path: [
          '/*/m/wishlist/listview',
          '/*/m/wishlist/creation',
          '/*/m/wishlist/item-selection',
          '/*/m/wishlist/information',
          '/*/m/rfq/:rfqId/review',
        ],
        component: AsyncSubmitRequestForQuoteV2,
        data: {
          logo: true,
          white: true,
        },
        routes: [
          {
            path: ['/*/m/wishlist/listview'],
            component: redirectFacilitator(AsyncWishlistProductToRfqAddItem),
            exact: true,
            data: { name: 'Wishlist' },
          },
          {
            path: ['/*/m/wishlist/creation'],
            component: redirectFacilitator(AsyncWishlistProductToRfq),
            exact: true,
            data: { name: 'Creation' },
          },
          {
            path: ['/*/m/wishlist/item-selection'],
            component: redirectFacilitator(requireAuth(AsyncSubmitRfqAddItem)),
            exact: true,
            data: { name: 'Update Items' },
          },
          {
            path: ['/*/m/wishlist/information'],
            component: redirectFacilitator(
              requireAuth(AsyncSubmitRfqInformation)
            ),
            exact: true,
            data: { name: 'Engagement Details' },
          },
          // by passing this step into the rfq workflow this allows us to easily
          // step into the normal workflow after the user creates the project
          {
            path: ['/*/m/rfq/:rfqId/review', '/*/m/rfq/:rfqId/review'],
            component: requireAuth(AsyncSubmitRfqReviewAndSubmit),
            exact: true,
            data: { name: 'Review' },
          },

          {
            component: AsyncPageError,
          },
        ],
      },
    ],
  },
];

export default Routes;
