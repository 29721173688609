import { isEmpty } from 'lodash';

import fetchJson from 'utility/fetchJson';
import { BASE } from '../../constants';

export async function getOpportunities({ filters } = {}) {
  const body = { ...filters };
  const response = await fetchJson(`${BASE}/api/rfq/group/list/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function getMfgOpportunities({ filters } = {}) {
  const body = { ...filters };
  const response = await fetchJson(`${BASE}/api/mrfq/group/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getItemOpportunities({ filters }) {
  const body = { ...filters };
  const response = await fetchJson(`${BASE}/api/rfq/item/list/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}

export async function getMfgItemOpportunities({ filters }) {
  const body = { ...filters };
  const response = await fetchJson(`${BASE}/api/mrfq/item/list/`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
  return response;
}
