import { get, isEmpty } from 'lodash';
import Promise from 'bluebird';
import tree from 'state';
import generatePagination, { generateSort } from 'utility/generatePagination';
import { getSortForRequest } from 'utility/reverseUrlFilters';

const inFlight = {};

// Temporary initial options to spread into uses of AsyncTreeRequester until this file is converted to TypeScript
export const emptyAsyncTreeRequesterOptions = {
  ignore404: undefined,
  sorting: undefined,
  filters: undefined,
  pagination: undefined,
  grouping: undefined,
  metadata: undefined,
  delayedLoading: undefined,
  cursor: undefined,
  path: undefined,
  errorPath: undefined,
  func: undefined,
  transformer: undefined,
  onSuccess: undefined,
  onError: undefined,
  handleResult: undefined,
  isDeleting: undefined,
  isSaving: undefined,
};

export default async function asyncTreeRequester({
  ignore404,
  sorting,
  filters,
  pagination,
  grouping,
  metadata,
  delayedLoading,
  cursor,
  path,
  errorPath,
  func,
  transformer,
  onSuccess,
  onError,
  handleResult,
  isDeleting,
  isSaving,
}) {
  let data;
  const c = cursor;
  const cursorHash = `${c ? c.hash : 'd'}-${(path || []).join('-')}`;
  if ((!c || !path) && !handleResult) {
    throw new Error('Path and Cursor Must Be Present');
  }
  const cursorData = c ? c.get(path) : null;
  let initialSet = 'merge';
  if (!cursorData) {
    initialSet = 'set';
  }

  if (get(cursorData, 'loading')) {
    // todo check pagination and filters
    return inFlight[cursorHash] && inFlight[cursorHash].catch((err) => err);
  }

  if (c) {
    c[initialSet](path, { loading: true, isDeleting, isSaving }); // if the path does not exist we cannot merge
  }

  const sortingCopy = sorting || getSortForRequest();
  const reqSorting = !isEmpty(sortingCopy) ? generateSort(sortingCopy) : {};

  try {
    // Legacy Documentation (speculative)
    // We assign a promise to a cursor dictionary to prevent race conditions when setting global state with async requests
    // This allows us to set a loading state, prevent overlapping requests, and only await the response data of the first request
    // However, it is important to note that this can cause immediate subsequent requests (triggered by initial metadata loading, etc.) to be ignored entirely
    // This can be detected by logging that an action was called, but its effect is never called
    // Or by logging the cursorData.loading condition above (this seems to be where we decide to cancel a concurrent request)
    const reqFunc = func({
      pagination,
      filters,
      sorting: reqSorting,
      grouping,
    });
    inFlight[cursorHash] = reqFunc;
    data = await reqFunc;
    inFlight[cursorHash] = null;

    if (data.page) {
      data.pagination = generatePagination(data);
    }

    if (sorting && data.page) {
      // sorting does not come back by default from the API. We need to get the defaults and set it so components know about it
      data.sort = generateSort(reqSorting);
    }

    if (handleResult) {
      // DO NOTHING - LET FUNCTION HANDLE RESULT
    } else if (onSuccess) {
      onSuccess(data, { cursor, path, errorPath });
    } else {
      const setType = c.get(path) ? 'merge' : 'set';
      c[setType](path, {
        result: transformer
          ? transformer({ result: data, cursor: c.get(path), metadata })
          : data,
        error: false,
        loading: false,
        isDeleting: false,
        isSaving: false,
      });
      if (errorPath) {
        c.set(errorPath, null);
      }
    }
  } catch (err) {
    if (ignore404 && (err.status === '404' || err.status === 404)) {
      data = {};
    } else if (handleResult) {
      data = { error: err.message };
    } else if (onError) {
      onError(err, { cursor, path, errorPath });
    } else {
      if (errorPath) {
        c.set(errorPath, { error: err.message });
      } else {
        const setType = c.get(path) ? 'merge' : 'set';
        c[setType](path, { error: err.message });
      }
      data = { error: err.message };
    }
  }
  await Promise.delay(delayedLoading || 0);
  if (c) {
    const setType = c.get(path) ? 'merge' : 'set';
    c[setType](path, { loading: false, isDeleting: false, isSaving: false });
    await Promise.delay(10);
    tree.commit();
  }
  return data;
}
