import React, { useState, useEffect } from 'react';
import { canUseDOM } from 'exenv';
import { size, filter, trim, toLower, includes, map } from 'lodash';
import { Tag } from '@zendeskgarden/react-tags';
import {
  Dropdown,
  Menu,
  Multiselect,
  Item,
  Field,
} from '@zendeskgarden/react-dropdowns';
import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
import { PropTypes } from 'prop-types';
import useDebounce from 'hooks/useDebounce.ts';

const { custom_blue: customBlue } = variables;

export const Wrapper = styled.div``;

export default function MultiSelectFormItem({
  medium,
  small,
  itemSelection,
  allowCreate,
  defaultValue,
  title,
  optionValues,
  selectedItems,
  setSelectedValue,
  popperModifiers,
  type,
  search,
}) {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [matchingOptions, setMatchingOptions] = useState(optionValues);

  const ss = canUseDOM && JSON.stringify(optionValues);

  const filterMatchingOptions = (value) => {
    async function searchMore(_value) {
      setSearching(true);
      await search(_value);
      setSearching(false);
    }
    const matchingOps =
      value === '' && search
        ? []
        : filter(optionValues, (option) =>
            includes(
              toLower(trim(option.name || '')),
              toLower(trim(value || ''))
            )
          );

    if (!size(matchingOps) && search && value) {
      searchMore(value);
    }

    setMatchingOptions(matchingOps);
    setIsLoading(false);
  };

  const debouncedFilterMatchingOptions = useDebounce(
    filterMatchingOptions,
    300
  );

  useEffect(() => {
    setIsLoading(true);
    debouncedFilterMatchingOptions(inputValue);
  }, [debouncedFilterMatchingOptions, inputValue]);

  useEffect(() => {
    if (!searching) {
      setMatchingOptions(optionValues);
    }
  }, [ss, searching]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderOptions = () => {
    const options = matchingOptions;
    if (isLoading || searching) {
      return <Item disabled>Loading items...</Item>;
    }

    if (size(options) === 0 && inputValue) {
      return (
        <>
          <Item disabled>No matches found</Item>
          {allowCreate !== false ? (
            <Item
              value={{ value: inputValue, label: inputValue }}
              style={{ color: customBlue }}
              hoverColorLight
            >
              Add {type || 'Item'}: {inputValue}
            </Item>
          ) : null}
        </>
      );
    }
    if (size(options) === 0 && !inputValue) {
      return <Item disabled>Search{allowCreate && ' or Add Value'}</Item>;
    }

    const existingOption = options.some(option => option.value === inputValue);

    return (
      <>
        {map(options, (option) => (
          <Item key={option.value || option.name} value={option}>
            <span>{option.name || option.label}</span>
          </Item>
        ))}
        {allowCreate !== false && inputValue && !existingOption ? (
          <Item
            value={{ value: inputValue, label: inputValue }}
            style={{ color: customBlue }}
            hoverColorLight
          >
            Add {type || 'Item'}: {inputValue}
          </Item>
        ) : null}
      </>
    );
};

  return (
    <Wrapper>
      <Dropdown
        inputValue={inputValue || ''}
        selectedItems={selectedItems || []}
        popperModifiers={popperModifiers}
        downshiftProps={{
          defaultHighlightedIndex: 0,
          itemToString: (item) => item && (item.name || item.label),
        }}
        onSelect={(items) => setSelectedValue(items)}
        onStateChange={(changes) => {
          if (Object.prototype.hasOwnProperty.call(changes, 'inputValue')) {
            setInputValue(changes.inputValue);
          }
        }}
      >
        <Field>
          <Multiselect
            className="multi-select"
            itemSelection={itemSelection}
            medium={medium}
            small={small}
            style={{ padding: '0.71429em 0' }}
            placeholder={defaultValue || title}
            isCompact
            renderItem={({ value }) => (
              <Tag className="form-option-tag">
                <span style={{ fontSize: '12px' }}>
                  {value.name || value.label}
                </span>
              </Tag>
            )}
          />
        </Field>
        <Menu maxHeight="200px" isCompact>
          {renderOptions()}
        </Menu>
      </Dropdown>
    </Wrapper>
  );
}

MultiSelectFormItem.propTypes = {
  medium: PropTypes.bool,
  small: PropTypes.bool,
  itemSelection: PropTypes.bool,
  allowCreate: PropTypes.bool,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  optionValues: PropTypes.array,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  selectedItems: PropTypes.array,
  setSelectedValue: PropTypes.func,
  popperModifiers: PropTypes.shape({}),
  type: PropTypes.string,
  search: PropTypes.func,
};
