import styled from 'styled-components/macro';

import { variables } from 'theme/variables';

const { color_grey_400: colorGrey400 } = variables;

export const Divider = styled.hr`
  color: ${colorGrey400};
  margin: 10px 0;
  width: 100%;
`;
