import { useState, useEffect } from 'react';
import { STREAM_API_KEY, STREAM_APP_ID } from 'constants.js';
import { useBranch } from 'baobab-react/hooks';
import * as stream from 'getstream';
import { fetchActivityFeed } from 'state/activity/actions';

let STREAM_CLIENT;

const useStreamNotificationFeed = ({
  isOpenGlobal,
  fetchDefault,
  fetchLimit,
  feedId,
  feedGroup,
  useActivityFeed,
  // Allows us to append feed data to the exisitng list with fetchMore rather then using a pagination UI
  persistFeed,
} = {}) => {
  const [notificationFeedClient, setNotificationFeedClient] =
    useState(undefined);
  const [feedStatus, setFeedStatus] = useState({ loading: true });
  const [feed, setFeedNotificationCounter] = useState([]);
  const [feedList, setFeedList] = useState([]);
  const [feedFlatList, setFeedFlatList] = useState([]);
  const [feedPage, setFeedPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);

  const { session } = useBranch({
    session: ['authentication', 'session'],
  });

  const sessionNotification = session && session.notification;
  const sessionActivity = session && session.activity;

  useEffect(() => {
    async function getFeedFromApi(_feedId, page, limit) {
      setFeedStatus({ loading: true });
      const ff = await fetchActivityFeed(_feedId, { page, limit, feedGroup });
      if (persistFeed) {
        setFeedFlatList((previous) => [...previous, ...ff.results]);
      } else {
        setFeedFlatList(ff.results);
      }
      if (!ff.next) {
        setLastPage(true);
      }
      setFeedStatus({ loading: false, error: ff.error });
    }
    if (sessionNotification && sessionActivity) {
      try {
        const client =
          STREAM_CLIENT || stream.connect(STREAM_API_KEY, null, STREAM_APP_ID);
        if (!STREAM_CLIENT) {
          STREAM_CLIENT = client;
        }

        if (useActivityFeed) {
          const activityFeed = client.feed(
            sessionActivity.stream,
            sessionActivity.feed,
            sessionActivity.token
          );
          setNotificationFeedClient({ client: activityFeed, flat: true });
        } else if (!feedId) {
          const notificationFeed = client.feed(
            sessionNotification.stream,
            sessionNotification.feed,
            sessionNotification.token
          );
          setNotificationFeedClient({ client: notificationFeed, flat: false });
        } else {
          getFeedFromApi(feedId, feedPage, fetchLimit);
        }
      } catch (err) {
        setFeedStatus({ loading: false, error: err.message });
        console.error('Error Loading Notification Stream');
      }
    }
  }, [
    feedId,
    sessionActivity,
    sessionNotification,
    useActivityFeed,
    feedGroup,
    feedPage,
    fetchLimit,
    persistFeed,
  ]);

  async function activityFeedNotificationCounterSuccess({ results = [] } = {}) {
    setFeedNotificationCounter((prevFeed) => [...prevFeed, ...results]);
    return true;
  }

  function streamFailCallback(data) {
    console.log(data, 'error in stream feed');
  }

  async function flatActivityFeedSuccess({ results = [] } = {}) {
    setFeedFlatList(results);
    return true;
  }

  async function streamUserActivity({ results = [] } = {}) {
    setFeedList(results);
    return true;
  }

  async function updateNotificationCounter({ results = [] } = {}) {
    if (results[0]) {
      setFeedNotificationCounter((prevFeed) => [
        ...prevFeed,
        {
          verb: results[0].verb,
          actor_count: 1,
          activity_count: 1,
          is_seen: false,
          activities: results,
        },
      ]);
    }
    return true;
  }

  useEffect(() => {
    // Get Header Notification Feed
    if (notificationFeedClient && !notificationFeedClient.flat) {
      notificationFeedClient.client
        .get()
        .then(activityFeedNotificationCounterSuccess, streamFailCallback);
      notificationFeedClient.client.subscribe((d) =>
        updateNotificationCounter({ results: d.new })
      );
    }
  }, [notificationFeedClient]);

  useEffect(() => {
    // Get Flat Activity Notification Feed
    if (notificationFeedClient && notificationFeedClient.flat) {
      setFeedStatus({ flatActivityloading: true });
      const limit = fetchLimit || 60;
      notificationFeedClient.client
        .get({ limit, offset: (feedPage - 1) * limit, mark_seen: true })
        .then(flatActivityFeedSuccess, streamFailCallback)
        .then(() => setFeedStatus({ flatActivityloading: false }));
    }
  }, [feedPage, fetchLimit, notificationFeedClient]);

  useEffect(() => {
    // Get Grouped Notification Feed
    if (
      notificationFeedClient &&
      !notificationFeedClient.flat &&
      (isOpenGlobal || fetchDefault)
    ) {
      setFeedStatus({ loading: true });
      const limit = fetchLimit || 40;
      notificationFeedClient.client
        .get({ limit, offset: (feedPage - 1) * limit, mark_seen: true })
        .then(streamUserActivity, streamFailCallback)
        .then(() => setFeedStatus({ loading: false }));
    }
  }, [
    feedPage,
    fetchDefault,
    fetchLimit,
    isOpenGlobal,
    notificationFeedClient,
  ]);

  function fetchMore() {
    setFeedPage((previous) => previous + 1);
  }

  return {
    feedList,
    feed, // this is counter for badge in notification dropdown
    feedFlatList,
    feedStatus,
    loading: feedStatus.loading,
    error: feedStatus.error,
    resetNotifications: () => setFeedNotificationCounter([]),
    fetchMore,
    lastPage,
  };
};

export default useStreamNotificationFeed;
