import {
  convertToRaw,
  convertFromRaw,
  RichUtils,
  getDefaultKeyBinding,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import linkifyHtml from 'linkify-html';
import { replace } from 'lodash';

const OPTIONS = {
  inlineStyles: {
    BOLD: {
      style: { fontWeight: 'bold' },
    },
    ITALIC: {
      style: { fontStyle: 'italic' },
    },
    UNDERLINE: {
      style: { textDecoration: 'underline' },
    },
  },
};

export function getMessageDraftValues(editorState) {
  const draft = convertToRaw(editorState.getCurrentContent());
  let html = stateToHTML(editorState.getCurrentContent(), OPTIONS);
  html = linkifyHtml(html);
  return { draft, html };
}

export function draftjsonToHtml(draft) {
  let html = convertFromRaw(draft.draft, OPTIONS);
  html = stateToHTML(html, OPTIONS);
  html = linkifyHtml(html);
  return html;
}

export function getRawDraft(editorState) {
  const draft = convertToRaw(editorState.getCurrentContent());
  return { draft };
}

export function trimPastedText(text, html) {
  // Removes all whitespace greater than 1 space
  // This is important for pasting from applications such as Outlook, where a the copied HTML inner text contains extra spaces due to arbitrary line breaks and indentation
  const trimmedHtml = replace(html, /  +/g, '');
  return { text, html: trimmedHtml };
}

// https://github.com/facebookarchive/draft-js/blob/main/website/src/components/DraftEditorExample/index.js
export function mapKeyToEditorCommand(e, editorState, setEditorState) {
  function handleTab() {
    const selection = editorState.getSelection();
    const key = selection.getAnchorKey();
    const content = editorState.getCurrentContent();
    const currentBlock = content.getBlockForKey(key);
    const previousBlock = content.getBlockBefore(key);
    const currentBlockType = currentBlock && currentBlock.getType();
    const previousBlockType = previousBlock && previousBlock.getType();
    const isFirstItemInList = currentBlockType !== previousBlockType;
    const newEditorState = RichUtils.onTab(
      e,
      editorState,
      // maxDepth: Prevent a list block from being indented beyond one level deeper than the previous one
      !isFirstItemInList ? previousBlock.getDepth() + 1 : 0
    );
    if (newEditorState !== editorState) {
      setEditorState({ editorState: newEditorState });
    }
    return null;
  }
  switch (e.keyCode) {
    case 9: // Tab key
      return handleTab();
    default:
      return getDefaultKeyBinding(e);
  }
}

// https://github.com/facebookarchive/draft-js/blob/main/website/src/components/DraftEditorExample/index.js
// If the user changes block type before entering any text, we can
// either style the placeholder or hide it. Let's just hide it now.
export function shouldHidePlaceholder(editorState) {
  let hidePlaceholder = false;
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      hidePlaceholder = true;
    }
  }
  return hidePlaceholder;
}
