import { isDate, isObject, isEqual } from 'lodash';

type Values = {
  [key: string]: any;
};

export function getDirtyFieldValues(values: Values, initialValues: Values) {
  const dirtyFieldValues: Values = {};
  Object.entries(values).forEach(([key, value]) => {
    if (isDate(value) && isDate(initialValues[key])) {
      if (value.toString() !== initialValues[key].toString()) {
        dirtyFieldValues[key] = value;
      }
    } else if (isObject(value) && isObject(initialValues[key])) {
      if (!isEqual(value, initialValues[key])) {
        dirtyFieldValues[key] = value;
      }
    } else if (value !== initialValues[key]) {
      dirtyFieldValues[key] = value;
    }
  });
  return dirtyFieldValues;
}
