import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import { map } from 'lodash';
import { MD } from '@zendeskgarden/react-typography';
import { useBranch } from 'baobab-react/hooks';
import Icon from 'components/Icon/Icon';
import { toggleExpand } from 'state/sidebarPage/actions';
import { isFacilitator, isPartner } from 'utility/hasAccount';
import { PIPE_SEARCH_ANALYTICS_URL } from '../../../constants';
import {
  CompressButton,
  SidebarHeader,
  DashboardSidebarMenu,
  NavWrapper,
  DropDownLink,
  SidebarLabel,
  SubMenuText,
  NavIcon,
  SubMenuIcon,
  MobileSidebarMenuLinkAdditions,
} from './DashboardSidebarMenu.styles';
import SidebarMenuLink from '../SidebarMenuLink';
import { variables } from '../../../theme/variables';
import {
  facilitatorSideBarData,
  adminSideBar,
  partnerSideBarData,
  endUserSideBarData,
  settingsSideBarAdditionalData,
  settingsFacilitatorSideBarAdditionalData,
} from './DashboardMenuData';
import { ExternalLink, StyledAnchor } from '../SidebarMenuLink.styles';
import MenuIcon from '../MenuIcon';

const { custom_blue: customBlue } = variables;

function MainSiteLinks() {
  return <DashboardSidebarMenu className="d-sidebar-menu" />;
}

export { MainSiteLinks };

function EndUserSideBar({ onClick }) {
  return (
    <DashboardSidebarMenu>
      {map(endUserSideBarData, (item, index) => (
        <SidebarMenuLink
          activeRoutes={['dashboard/market-comparables']}
          // LINT OVERRIDE #1
          // https://github.com/yannickcr/eslint-plugin-react/issues/1123
          // Nothing is inherently unique about these elements
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={onClick}
          to={item.path}
          icon={item.icon}
        >
          <span style={{ marginLeft: '10px' }}>{item.title}</span>
        </SidebarMenuLink>
      ))}
      <MobileSidebarMenuLinkAdditions>
        {map(settingsSideBarAdditionalData, (item, index) => (
          <SidebarMenuLink
            item={item}
            // https://github.com/yannickcr/eslint-plugin-react/issues/1123
            // Nothing is inherently unique about these elements
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={onClick}
            to={item.path}
            icon={item.icon}
            settingsLinks
          >
            <span style={{ marginLeft: '10px' }}>{item.title}</span>
          </SidebarMenuLink>
        ))}
      </MobileSidebarMenuLinkAdditions>
    </DashboardSidebarMenu>
  );
}

EndUserSideBar.propTypes = {
  onClick: PropTypes.func,
};

export { EndUserSideBar };

function SidebarMenus({ item, isExpanded }) {
  const { subNav, title } = item;
  const [displayMenu, setDisplayMenu] = useState(true);
  const showSubnav = () =>
    setDisplayMenu((displayMenuPrevious) => !displayMenuPrevious);

  const renderIcon = () => {
    if (item.subNav && displayMenu) {
      return item.iconOpened;
    }
    if (item.subNav) {
      return item.iconClosed;
    }
    return null;
  };

  return (
    <>
      {isExpanded && (
        <SidebarMenuLink
          onClick={item.subNav && showSubnav}
          fontAwesome
          to={item.path}
          icon={item.icon}
          // Highlight Dashboard menu item when we are at its path
          exact={item.title === 'Dashboard'}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
              width: '185px',
            }}
          >
            <MD tag="p" style={{ color: '#23467E' }}>
              {title}
            </MD>
            <NavIcon>{renderIcon()}</NavIcon>
          </div>
        </SidebarMenuLink>
      )}
      {/* Display Dashboard icon when menu is collapsed */}
      {!isExpanded && item.title === 'Dashboard' && (
        <DropDownLink
          to={item.path}
          exact
          styledprops={{
            isExpanded,
          }}
        >
          <SubMenuIcon isExpanded={isExpanded}>
            <p>{item.icon}</p>
          </SubMenuIcon>
          <SidebarLabel>
            <SubMenuText>{isExpanded ? item.title : null}</SubMenuText>
          </SidebarLabel>
        </DropDownLink>
      )}
      {displayMenu && subNav
        ? map(subNav, (subNavItem, index) => (
            <DropDownLink
              to={subNavItem.path}
              key={index}
              // Prevent passing prop to DOM element <a> https://github.com/styled-components/styled-components/issues/1198#issuecomment-447885637
              styledprops={{
                isExpanded,
              }}
            >
              <SubMenuIcon isExpanded={isExpanded}>
                <p>{subNavItem.icon}</p>
              </SubMenuIcon>
              <SidebarLabel>
                <SubMenuText>
                  {isExpanded ? subNavItem.title : null}
                </SubMenuText>
              </SidebarLabel>
            </DropDownLink>
          ))
        : null}
    </>
  );
}

SidebarMenus.propTypes = {
  item: PropTypes.shape({
    subNav: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
        icon: PropTypes.node,
      })
    ),
    title: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.node,
    iconClosed: PropTypes.node,
    iconOpened: PropTypes.node,
  }),
  isExpanded: PropTypes.bool,
};

export function FacilitatorSideBar() {
  const { sidebarExpanded } = useBranch({
    sidebarExpanded: ['sidebarPage', 'sidebarExpanded'],
  });
  return (
    <DashboardSidebarMenu>
      {map(facilitatorSideBarData, (item, index) => (
        <SidebarMenus
          item={item}
          // https://github.com/yannickcr/eslint-plugin-react/issues/1123
          // Nothing is inherently unique about these elements
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          isExpanded={sidebarExpanded}
        />
      ))}
      <MobileSidebarMenuLinkAdditions>
        {map(settingsFacilitatorSideBarAdditionalData, (item, index) => (
          <SidebarMenus
            item={item}
            // https://github.com/yannickcr/eslint-plugin-react/issues/1123
            // Nothing is inherently unique about these elements
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            isExpanded={sidebarExpanded}
          />
        ))}
      </MobileSidebarMenuLinkAdditions>
      <ExternalLink>
        <StyledAnchor
          href={PIPE_SEARCH_ANALYTICS_URL}
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: sidebarExpanded ? null : '10px' }}
        >
          <MenuIcon
            isExpanded={sidebarExpanded}
            icon={<Icon fontAwesome icon="warehouse-alt" color={customBlue} />}
          />
          {sidebarExpanded ? (
            <MD
              tag="p"
              style={{ color: '#23467E', fontSize: '12px', marginLeft: '10px' }}
            >
              Analytics
            </MD>
          ) : null}
        </StyledAnchor>
      </ExternalLink>
      <ExternalLink>
        <StyledAnchor
          href="https://pipesearchhelp.zendesk.com/hc/en-us"
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: sidebarExpanded ? null : '10px' }}
        >
          <MenuIcon
            isExpanded={sidebarExpanded}
            icon={<Icon fontAwesome icon="life-ring" color={customBlue} />}
          />
          {sidebarExpanded ? (
            <MD
              tag="p"
              style={{ color: '#23467E', fontSize: '12px', marginLeft: '10px' }}
            >
              Help Center
            </MD>
          ) : null}
        </StyledAnchor>
      </ExternalLink>
    </DashboardSidebarMenu>
  );
}

export function PartnerSideBar() {
  const { sidebarExpanded } = useBranch({
    sidebarExpanded: ['sidebarPage', 'sidebarExpanded'],
  });
  return (
    <DashboardSidebarMenu>
      {map(partnerSideBarData, (item, index) => (
        <SidebarMenus
          item={item}
          // https://github.com/yannickcr/eslint-plugin-react/issues/1123
          // Nothing is inherently unique about these elements
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          isExpanded={sidebarExpanded}
        />
      ))}
      <MobileSidebarMenuLinkAdditions>
        {map(settingsSideBarAdditionalData, (item, index) => (
          <SidebarMenus
            item={item}
            // https://github.com/yannickcr/eslint-plugin-react/issues/1123
            // Nothing is inherently unique about these elements
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            isExpanded={sidebarExpanded}
          />
        ))}
      </MobileSidebarMenuLinkAdditions>
    </DashboardSidebarMenu>
  );
}

export function AdminSideBar() {
  return (
    <DashboardSidebarMenu>
      {map(adminSideBar, (item, index) => (
        // https://github.com/yannickcr/eslint-plugin-react/issues/1123
        // Nothing is inherently unique about these elements
        // eslint-disable-next-line react/no-array-index-key
        <SidebarMenus item={item} key={index} />
      ))}
      <MobileSidebarMenuLinkAdditions>
        {map(settingsFacilitatorSideBarAdditionalData, (item, index) => (
          <SidebarMenus
            item={item}
            // https://github.com/yannickcr/eslint-plugin-react/issues/1123
            // Nothing is inherently unique about these elements
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
      </MobileSidebarMenuLinkAdditions>
    </DashboardSidebarMenu>
  );
}

function CollapseSetting({ sidebarExpanded }) {
  return (
    <SidebarHeader
      style={{ overflow: 'hidden', borderBottom: '1px solid transparent' }}
      sidebarExpanded={sidebarExpanded}
      onClick={() => {
        toggleExpand();
      }}
    >
      <CompressButton>
        <div>
          <Flex
            alignCenter
            noWrap
            justifyCenter={!sidebarExpanded}
            style={!sidebarExpanded ? { maxWidth: '46px' } : {}}
          >
            {!sidebarExpanded ? (
              <Icon
                color={customBlue}
                fontAwesome
                icon="expand-arrows-alt"
                fontSize="16px"
              />
            ) : (
              <Icon
                color={customBlue}
                fontAwesome
                icon="compress-arrows-alt"
                fontSize="16px"
              />
            )}
            {sidebarExpanded ? (
              <MD
                style={{
                  whiteSpace: 'nowrap',
                  color: '#abb7c7',
                  paddingLeft: '10px',
                }}
                uppercase
                slate
              >
                COLLAPSE SIDEBAR
              </MD>
            ) : null}
          </Flex>
        </div>
      </CompressButton>
    </SidebarHeader>
  );
}

CollapseSetting.propTypes = {
  sidebarExpanded: PropTypes.bool,
};

export { CollapseSetting };

function DashboardSidebarMenuWrapper({ disableCollapse }) {
  const facilitator = isFacilitator();
  const partner = isPartner();

  const { sidebarExpanded, dashboardType } = useBranch({
    dashboardType: ['application', 'accountType'],
    sidebarExpanded: ['sidebarPage', 'sidebarExpanded'],
  });

  const hasFacilitatorRole =
    ((dashboardType && dashboardType === 'facilitator') || !dashboardType) &&
    (facilitator || dashboardType === 'facilitator');

  const hasPartnerRole =
    ((dashboardType && dashboardType === 'facilitator_partner') ||
      !dashboardType) &&
    (partner || dashboardType === 'facilitator_partner');

  const renderSidebars = () => {
    if (hasFacilitatorRole) {
      return <FacilitatorSideBar />;
    }
    if (hasPartnerRole) {
      return <PartnerSideBar />;
    }
    return <EndUserSideBar />;
  };

  return (
    <div>
      {disableCollapse ? null : (
        <CollapseSetting sidebarExpanded={sidebarExpanded} />
      )}
      <NavWrapper className="nav-wrapper">{renderSidebars()}</NavWrapper>
    </div>
  );
}

DashboardSidebarMenuWrapper.propTypes = {
  disableCollapse: PropTypes.bool,
};

export default DashboardSidebarMenuWrapper;
