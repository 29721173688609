import React, { useState, useCallback } from 'react';
import { SM, MD } from '@zendeskgarden/react-typography';
import { useBranch } from 'baobab-react/hooks';

import CompanyLookupV2 from 'components/CompanyLookup/CompanyLookupV2';
import { Button, emptyButtonProps } from 'theme/Button';
import { getScrapValueSpreadsheet } from 'state/scrapValue/actions.ts';
import { NewAlert } from 'theme/Alert';
import {
  CompanyAutocompleteResponse,
  ContactAutocompleteResponse,
  FileUriResponse,
} from 'types/types.ts';
import { useTaskStatus } from 'hooks/useTaskStatus.ts';
import { downloadFileFromUrl } from 'utility/downloadFile';
import { getRole } from 'utility/hasAccount';

export function AddScrapValueDownload() {
  const [company, setCompany] = useState<CompanyAutocompleteResponse | null>(
    null
  );
  const [contact, setContact] = useState<ContactAutocompleteResponse | null>(
    null
  );

  const [isPreparingGetSpreadsheet, setIsPreparingGetSpreadsheet] =
    useState(false);
  const [getSpreadsheetError, setGetSpreadsheetError] = useState<string | null>(
    null
  );

  const { value: role } = getRole() || {};
  const { clientId } = useBranch({
    clientId: [
      'authentication',
      'session',
      'roles',
      role,
      'data',
      'company',
      'id',
    ],
  });
  const { waitForTaskResult } = useTaskStatus();

  const getSpreadsheet = useCallback(async () => {
    setGetSpreadsheetError(null);
    const data = {
      company_id: company?.id || null,
      contact_id: contact?.id || null,
    };
    const response = await getScrapValueSpreadsheet(data);
    if (response.error) {
      setGetSpreadsheetError(response.error);
    } else {
      setIsPreparingGetSpreadsheet(true);
      try {
        const taskResult = await waitForTaskResult<FileUriResponse>(
          response.task_id
        );
        if (!taskResult) throw new Error('Missing uri or filename');

        downloadFileFromUrl(taskResult.uri, taskResult.file_name);
      } catch (taskError) {
        const _taskError = taskError as Error;
        setGetSpreadsheetError(_taskError.message);
      }
      setIsPreparingGetSpreadsheet(false);
    }
  }, [company, contact, waitForTaskResult]);

  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        <MD navy bold>
          Download Spreadsheet
        </MD>
        <SM slate>
          Download a .xlsx spreadsheet to add new entries to the Scrap Value
          table. You can optionaly select a Company and Contact that will be
          applied to the scrap value spreadsheet.
        </SM>
      </div>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <SM bold navy scaled tag="span">
          Company
        </SM>
        <CompanyLookupV2
          searchType="company"
          placeholder="Select a Company"
          clientId={clientId}
          style={{ marginTop: 10 }}
          selectedItem={company}
          setSelectedValue={setCompany}
          reset
        />
      </div>
      <div style={{ width: '100%', marginTop: '20px' }}>
        <SM bold navy scaled tag="span">
          Contact
        </SM>
        <CompanyLookupV2
          searchType="contact"
          placeholder="Select a Contact"
          clientId={clientId}
          style={{ marginTop: 10 }}
          selectedItem={contact}
          setSelectedValue={setContact}
          contactIsUser
          reset
        />
      </div>
      <Button
        {...emptyButtonProps}
        primary
        onClick={getSpreadsheet}
        loading={isPreparingGetSpreadsheet}
        style={{ margin: '20px 0' }}
      >
        Generate .XLSX
      </Button>
      {getSpreadsheetError && (
        <NewAlert
          label="Generate .XLSX Error"
          title={getSpreadsheetError}
          type="error"
        />
      )}
    </>
  );
}
