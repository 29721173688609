import React, { memo, useCallback } from 'react';
import { Col, Row } from '@zendeskgarden/react-grid';
import { MD } from '@zendeskgarden/react-typography';
import Flex from 'styled-flex-component';
import {
  get,
  isObject,
  values,
  filter as _filter,
  some,
  isEqual,
  size,
} from 'lodash';
import { variables } from 'theme/variables';
import PropTypes from 'prop-types';
import { FilterItemGroupWrapper } from './FilterItemGroup.styles';
import FilterItemValues from './components/FilterItemValues/FilterItemValues';
import FilterItemInputs from './components/FilterItemInputs/FilterItemInputs';
import FilterItemOperators from './components/FilterItemOperators/FilterItemOperators';
import FilterItemSelection from './components/FilterItemSelection/FilterItemSelection';

const { spacing_sm: spacingSm } = variables;

function FilterItemGroup({
  ignorePlaceholderPrefix,
  filter,
  type,
  onChangeFilters,
  currentFilters,
  selectionFilters,
  inline,
  menuZIndex,
  popperModifiers,
  titleStyle,
  medium,
  isMfg,
}) {
  const {
    items = [],
    title,
    inputType,
    warning,
    error,
    minDropdownWidth,
    filterOperators,
    value,
    multiple,
    disableSelectionOnRange,
    filter: filterFunc,
    selectedFilters,
    customValues,
    operator,
    ignoreTitle,
  } = filter;
  let { uom, operators = [] } = filter;
  const range = operator?.value === 'range';
  let isDropdown = inputType === 'FilterItemValues' || !!size(items);
  const isSelection = inputType === 'FilterItemSelection';

  if (range && disableSelectionOnRange) {
    isDropdown = false;
  }
  const changeValue = useCallback(
    (filterType, dropdownValue, otherValues) => {
      const updateValue = {};
      if (
        filterType === 'operator' &&
        isObject(value) &&
        get(dropdownValue, 'value') !== 'range' &&
        get(dropdownValue, 'field') !== 'quantity_uom'
      ) {
        // We want to reset value when going from range to normal value
        updateValue.value = isDropdown ? value?.min : values(value)[0] || '';
      } else if (
        filterType === 'operator' &&
        value &&
        !value?.min &&
        get(dropdownValue, 'value') === 'range'
      ) {
        // We want to change MIN to filter.value when going to range from normal value
        updateValue.value = disableSelectionOnRange ? {} : { min: value };
      }
      onChangeFilters('filters', type, {
        ...filter,
        [filterType]: dropdownValue,
        ...updateValue,
        ...otherValues,
      });
    },
    [disableSelectionOnRange, filter, isDropdown, onChangeFilters, type, value]
  );

  // similar to above `changeValue`, will update the item's filters in state, but
  // changing operator and operators needs to be done in one call to onChangeFilters
  function changeOperators(ops) {
    onChangeFilters(
      'filters',
      type,
      {
        ...filter,
        operator: ops[0],
        operators: ops,
      },
      {
        ignoreTouch: true, // We don't update dirty state because this is an initialization step
        isInitial: true,
      }
    );
  }

  const filteredItems = filterFunc ? filterFunc(items, currentFilters) : items;
  if (filterFunc && filteredItems === null) {
    isDropdown = false;
  }

  let minFilterItems;
  let maxFilterItems;
  const minRangeFilterValue = value?.min?.rangeFilterValue;
  const maxRangeFilterValue = value?.max?.rangeFilterValue;
  if (minRangeFilterValue && range) {
    maxFilterItems = _filter(filteredItems, (itm) =>
      itm.rangeFilterValue ? itm.rangeFilterValue > minRangeFilterValue : true
    );
  }
  if (maxRangeFilterValue && range) {
    minFilterItems = _filter(filteredItems, (itm) =>
      itm.rangeFilterValue ? itm.rangeFilterValue < maxRangeFilterValue : true
    );
  }

  if (!some(operators, operator) && filterOperators) {
    operators = filterOperators(currentFilters, isMfg);
  }

  // set new operators, and invalidate currently selected operator if necessary
  let operatorValue = operator;
  const filteredOperators =
    filterOperators && filterOperators(currentFilters, isMfg);
  if (filteredOperators && !isEqual(filteredOperators, operators)) {
    changeOperators(filteredOperators);
    operators = filteredOperators;
    [operatorValue] = filteredOperators;
  }

  uom = uom || (operatorValue ? operatorValue.label : null);
  const tr = { Feet: 'ft.', Meters: 'm.', Inches: 'in.' };
  uom = tr[uom] || uom;

  const renderFilterItems = useCallback(
    (_operators, { inline: _inline }) => {
      if (isSelection) {
        return (
          <FilterItemSelection
            type={type}
            uom={uom}
            warning={warning}
            error={error}
            selectionFilters={selectionFilters}
            optionValues={minFilterItems || filteredItems}
            selectedFilters={selectedFilters}
            selectedValue={value}
            setSelectedValue={(...otherVals) =>
              changeValue('value', ...otherVals)
            }
            currentFilters={currentFilters}
            title={title}
            allowMultiple={multiple}
            isMfg={isMfg}
            medium={medium}
          />
        );
      }
      if (isDropdown && range) {
        return (
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col>
              <FilterItemValues
                uom={uom}
                warning={warning}
                error={error}
                customValues={customValues}
                persistCasing
                range
                rangeKey="min"
                minDropdownWidth={minDropdownWidth}
                optionValues={minFilterItems || filteredItems}
                selectedValue={value}
                defaultValue="min"
                setSelectedValue={(...otherVals) =>
                  changeValue('value', ...otherVals)
                }
                title={title}
                menuZIndex={menuZIndex}
                popperModifiers={popperModifiers}
                medium={medium}
              />
            </Col>
            <Col>
              <FilterItemValues
                uom={uom}
                warning={warning}
                error={error}
                customValues={customValues}
                persistCasing
                range
                rangeKey="max"
                minDropdownWidth={minDropdownWidth}
                optionValues={maxFilterItems || filteredItems}
                selectedValue={value}
                defaultValue="max"
                setSelectedValue={(...otherVals) =>
                  changeValue('value', ...otherVals)
                }
                title={title}
                menuZIndex={menuZIndex}
                popperModifiers={popperModifiers}
                medium={medium}
              />
            </Col>
          </Row>
        );
      }
      if (isDropdown) {
        return (
          <FilterItemValues
            hideAdd
            uom={uom}
            warning={warning}
            error={error}
            multiple={multiple}
            customValues={customValues}
            persistCasing
            range={range}
            minDropdownWidth={minDropdownWidth}
            optionValues={filteredItems}
            selectedValues={multiple ? value : undefined}
            selectedValue={multiple ? undefined : value}
            defaultValue={`${
              !ignorePlaceholderPrefix ? 'Select' : ''
            } ${title}`}
            setSelectedValue={(...otherVals) =>
              changeValue('value', ...otherVals)
            }
            title={title}
            menuZIndex={menuZIndex}
            popperModifiers={popperModifiers}
            medium={medium}
          />
        );
      }
      return (
        <FilterItemInputs
          uom={uom}
          type={type}
          warning={warning}
          error={error}
          inputType={inputType}
          customValues={customValues}
          value={value || ''}
          onChangeInput={(...otherVals) => {
            changeValue('value', ...otherVals);
          }}
          range={range}
          title={title}
          // TODO Add range etc operators to inline inputs
          inline={_inline}
          operators={_operators}
          medium={medium}
        />
      );
    },
    [
      changeValue,
      currentFilters,
      customValues,
      error,
      filteredItems,
      ignorePlaceholderPrefix,
      inputType,
      isDropdown,
      isMfg,
      isSelection,
      maxFilterItems,
      medium,
      menuZIndex,
      minDropdownWidth,
      minFilterItems,
      multiple,
      popperModifiers,
      range,
      selectedFilters,
      selectionFilters,
      title,
      type,
      uom,
      value,
      warning,
    ]
  );

  return (
    <FilterItemGroupWrapper key={title}>
      {!inline && (
        <Flex
          className="filter-item-group-header"
          style={{ marginBottom: !ignoreTitle ? spacingSm : '' }}
          justifyBetween
        >
          {!ignoreTitle && (
            <MD className="filter-title" noMargin bold style={titleStyle}>
              {title}
            </MD>
          )}
          {operators && operators.length ? (
            <FilterItemOperators
              operators={operators}
              selectedOperator={operatorValue}
              onChangeOperator={(...otherOps) =>
                changeValue('operator', ...otherOps)
              }
            />
          ) : (
            <span />
          )}
        </Flex>
      )}
      {renderFilterItems(operators, { inline })}
    </FilterItemGroupWrapper>
  );
}

FilterItemGroup.propTypes = {
  ignorePlaceholderPrefix: PropTypes.bool,
  filter: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string,
    inputType: PropTypes.string,
    operators: PropTypes.arrayOf(PropTypes.shape({})),
    uom: PropTypes.string,
    operator: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    disableSelectionOnRange: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        min: PropTypes.shape({
          rangeFilterValue: PropTypes.string,
        }),
        max: PropTypes.shape({
          rangeFilterValue: PropTypes.string,
        }),
      }),
      PropTypes.shape({
        label: PropTypes.number,
        value: PropTypes.number,
      }),
      PropTypes.array,
    ]),
    filter: PropTypes.func,
    filterOperators: PropTypes.func,
    warning: PropTypes.string,
    error: PropTypes.bool,
    selectedFilters: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    customValues: PropTypes.bool,
    minDropdownWidth: PropTypes.string,
    multiple: PropTypes.bool,
    key: PropTypes.string,
    ignoreTitle: PropTypes.bool,
  }),
  type: PropTypes.string,
  onChangeFilters: PropTypes.func,
  currentFilters: PropTypes.shape({}),
  selectionFilters: PropTypes.shape({}),
  inline: PropTypes.bool,
  menuZIndex: PropTypes.number,
  popperModifiers: PropTypes.shape({}),
  titleStyle: PropTypes.shape({}),
  medium: PropTypes.bool,
  isMfg: PropTypes.bool,
};

export default memo(FilterItemGroup, (pp, np) => {
  const ppFilter = pp.filter;
  const npFilter = np.filter;
  const ppCurrentFilters = pp.currentFilters;
  const npCurrentFilters = np.currentFilters;

  const operatorCheck = ppFilter.operator === npFilter.operator;
  const operatorListCheck = isEqual(ppFilter.operators, npFilter.operators);
  const errorWarningCheck =
    ppFilter.error === npFilter.error && ppFilter.warning === npFilter.warning;
  const valueCheck = isEqual(ppFilter.value, npFilter.value);
  const itemsCheck = size(ppFilter.items) === size(npFilter.items);
  const categoryCheck =
    ppCurrentFilters.category?.value?.value ===
    npCurrentFilters.category?.value?.value;
  /* If a new OD selection is incompatible with the current #/FT selection, 
    the #/FT field is cleared and we need to update render.
    We want to do this only when there is a previous value and no new value,
    to avoid unneccessary re-renders.
  */
  let weightPerFootResetCheck = true;
  if (np.type === 'weight_per_foot' && ppFilter.value && !npFilter.value) {
    weightPerFootResetCheck = false;
  }
  const selectedFiltersGroupsCheck = isEqual(
    ppFilter.selectedFilters?.groups,
    npFilter.selectedFilters?.groups
  );
  const multipleCheck = ppFilter.multiple === npFilter.multiple;

  return (
    operatorCheck &&
    operatorListCheck &&
    errorWarningCheck &&
    valueCheck &&
    itemsCheck &&
    categoryCheck &&
    weightPerFootResetCheck &&
    selectedFiltersGroupsCheck &&
    multipleCheck &&
    (npFilter.memo ? npFilter.memo(pp, np) : true)
  );
});
