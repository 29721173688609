/* eslint-disable max-classes-per-file */
import { BASE } from 'constants.js';
import { useCallback, useEffect, useRef } from 'react';
import fetchJSON from 'utility/fetchJson';
import sleep from 'utility/sleep';

const TaskStatusRecheckDelay = 2000; // ms
const TaskStatusAutoCancelAfter = 300000; // ms; 300000 = 5min

enum TaskStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  COMPLETED = 'completed',
  ERROR = 'error',
}

class ErrorTaskCancelled extends Error {}
class ErrorTaskFailure extends Error {}
class ErrorTaskTimeout extends Error {}

export const useTaskStatus = ({
  noTimeout,
  noCancelOnUnmount,
}: UseTaskStatusProps = {}): UseTaskStatus => {
  const tasks = useRef<Tasks>({});

  const removeTask = useCallback(
    (taskId: string) => delete tasks.current[taskId],
    []
  );
  const cancelTask = useCallback(
    (taskId: string, cancelOnBackend = true) => {
      tasks.current[taskId]?.abort();
      removeTask(taskId);
      if (cancelOnBackend) {
        // TODO: make a query to the backend to cancel the task once the endpoint is available
      }
    },
    [removeTask]
  );

  const waitForTaskResult = useCallback(
    async <Result>(taskId: string) => {
      const controller = new AbortController();
      const { signal } = controller;
      tasks.current[taskId] = {
        stopAt: Date.now() + TaskStatusAutoCancelAfter,
        abort: () => controller.abort(),
      };

      type ResultWithErrorString = Result & { error: string };
      function checkResultForErrorString(
        result: Result
      ): result is ResultWithErrorString {
        const { error } = result as ResultWithErrorString;
        return error !== undefined && typeof error === 'string';
      }

      while (
        /** while the task is still in our watch list */
        tasks.current[taskId] &&
        /** and the task is not timed out */
        (noTimeout || Date.now() < tasks.current[taskId].stopAt)
      ) {
        try {
          const { status, result }: TaskStatusResponse<Result> =
            await fetchJSON(`${BASE}/api/tasks/status/${taskId}/`, {
              signal,
            });

          if (status === TaskStatus.COMPLETED) {
            removeTask(taskId);
            return result;
          }
          if (status === TaskStatus.ERROR) {
            removeTask(taskId);

            const errorMessage = checkResultForErrorString(result)
              ? result.error
              : 'Task failed.';
            throw new ErrorTaskFailure(errorMessage);
          }
        } catch (error) {
          if (error instanceof Error && error.name === 'AbortError') {
            removeTask(taskId);
            throw new ErrorTaskCancelled('Task was cancelled.');
          }

          removeTask(taskId);
          throw error;
        }

        await sleep(TaskStatusRecheckDelay);
      }

      if (!tasks.current[taskId]) {
        throw new ErrorTaskCancelled('Task was cancelled.');
      }

      removeTask(taskId);
      throw new ErrorTaskTimeout('Task timed out.');
    },
    [removeTask, noTimeout]
  );

  // cancel all tasks on unmount
  useEffect(
    () => () => {
      Object.keys(tasks.current).forEach((id) =>
        cancelTask(id, !noCancelOnUnmount)
      );
    },
    [cancelTask, noCancelOnUnmount]
  );

  return {
    waitForTaskResult,
    cancelTask,
  };
};

interface UseTaskStatusProps {
  noTimeout?: boolean;
  noCancelOnUnmount?: boolean;
}

interface UseTaskStatus {
  waitForTaskResult: <Result>(taskId: string) => Promise<Result>;
  cancelTask: (taskId: string, cancelOnBackend?: boolean) => void;
}

type Tasks = Record<
  string,
  {
    stopAt: number;
    abort: VoidFunction;
  }
>;

type TaskStatusResponse<Result> = {
  status: TaskStatus;
  result: Result;
};
