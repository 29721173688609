import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { Col, Row as GridRow } from '@zendeskgarden/react-grid';
import { MD } from '@zendeskgarden/react-typography';
import { get, map, replace, isFunction, includes } from 'lodash';
import styled from 'styled-components/macro';

import { openModal } from 'state/modals/actions';
import { trackClick } from 'utility/analytics';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { FullModalWrapper, ModalScrollContainer } from 'App.styles.js';
import history from 'historyContainer';
import { isFacilitator, isAdmin } from 'utility/hasAccount';
import InPageOverlay from 'components/InPageOverlay/InPageOverlay';
import { Button } from 'theme/Button';
import { variables } from 'theme/variables';
import TopicHeader from './components/TopicHeader/TopicHeader';
import { getValues } from '../../utility/urlUtils';

const { media_md: mediaMd } = variables;

const ButtonRow = styled(GridRow)`
  flex-direction: column;
  @media ${mediaMd} {
    flex-direction: row;
  }
`;
const ConfirmButtonCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media ${mediaMd} {
    justify-content: end;
    margin-bottom: 0;
  }
`;

const CancelButtonCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${mediaMd} {
    justify-content: start;
  }
`;

function RoutedModal({ location, route, match }) {
  const props = useMemo(
    () => ({ location, route, match }),
    [location, match, route]
  );
  const [isRouteRendered, setRendered] = useState(false);
  const [isDataFetched, setDataFetched] = useState(false);
  const [transitionOpen, setTransitionOpen] = useState(false);
  const [closeModalWarning, setCloseModalWarning] = useState(null);
  const [modalHistory, setModalHistory] = useState([]);
  const [pageRefreshed, setPageRefreshed] = useState(false);
  const [originTable, setOriginTable] = useState(null);
  const [originQuerystring, setOriginQuerystring] = useState(null);
  const [originSearch, setOriginSearch] = useState(null);

  const path = location.pathname;
  const modalString = get(path.match(/(\/m\/\w.*)|(\/m\/)/), '[0]');
  const basePath = replace(path, modalString, '');
  const previousPath = location.previous?.pathname;

  useEffect(() => {
    // If the previous location wasn't a modal, we have just opened the modal and we should save the previous querystring
    if (
      !includes(location.previous.pathname, '/m/') &&
      location.previous.search
    ) {
      const querystring = getValues({
        querystring: location.previous.search,
      });
      setOriginQuerystring(querystring);
      setOriginSearch(location.previous.search);
      if (includes(location.previous.pathname, 'opportunities/my-accounts')) {
        setOriginTable('my-accounts');
      } else {
        setOriginTable('opportunities');
      }
    }
  }, [location.previous.pathname, location.previous.search]);

  useEffect(() => {
    setModalHistory((previousModalHistory) => [...previousModalHistory, path]);
  }, [path]);

  // If the last entry in modalHistory is the same as the previous path in history, we refreshed
  useEffect(() => {
    if (modalHistory[modalHistory.length - 1] === previousPath) {
      setPageRefreshed(true);
    } else {
      setPageRefreshed(false);
    }
  }, [modalHistory, previousPath]);

  const handleClose = useCallback(() => {
    // if this modal is editing a listing then ...
    if (get(props, 'location.state.listingSeed.id')) {
      if (isFunction(route.unmountFunc)) {
        const listingId = props.location.state.listingSeed.id;
        route.unmountFunc(listingId);
      }
      history.goBack();
    } else if (!pageRefreshed) {
      // for all other uses of modal ( opportunities edit, opportunities create, listing create, & more )
      // If we refreshed, we can't use modal history and must push a new entry
      if (originSearch) {
        history.push(`${replace(path, modalString, '')}${originSearch}`);
      } else {
        history.push(replace(path, modalString, ''));
      }
    } else if (get(props, 'route.onCloseOverride')) {
      history.push(route.onCloseOverride);
    } else {
      history.push(replace(path, modalString, ''));
    }
  }, [modalString, originSearch, pageRefreshed, path, props, route]);

  const handleConfirmClose = () => {
    setTransitionOpen(false);
    setCloseModalWarning(null);
    if (isFunction(route.unmountFunc)) {
      route.unmountFunc(path, originQuerystring);
    }
    handleClose();
  };

  const handleCancelClose = () => {
    trackClick('cancelCloseRoutedModal', 'confirm', 'RoutedModal');
    setTransitionOpen(false);
    setCloseModalWarning(null);
  };

  const closeModal = useCallback(
    ({ ignoreCloseWarn, warning } = {}) => {
      trackClick('closeRoutedModal', 'x', 'RoutedModal');
      if (
        !isAdmin() &&
        !isFacilitator() &&
        route.modalIntercept &&
        route.modalIntercept(path)
      ) {
        openModal({
          type: route.modalIntercept(path, props),
          data: {
            propData: props,
            onClose: () => {
              if (isFunction(route.unmountFunc)) {
                route.unmountFunc();
              }
              history.push(replace(path, modalString, ''));
            },
          },
        });
      } else if (!ignoreCloseWarn && route.confirmClose) {
        if (warning) {
          setCloseModalWarning(warning);
        }
        setTransitionOpen(true);
      } else {
        handleClose();
      }
    },
    [handleClose, modalString, path, props, route]
  );

  const activeRouteData = get(
    matchRoutes(route.routes, location.pathname),
    '[0].route.data'
  );
  const routes = map(route.routes, (currentRoute) => ({
    ...currentRoute,
    basePath,
    rfqId: match.params.rfqId,
    businessId: match.params.businessId,
    parentParams: match.params,
    parentMatch: match,
    isModal: true,
    isFacilitator: isFacilitator(),
    isAdmin: isAdmin(),
  }));
  // PARAMS FOR REQUESTS
  const rfqId = get(match, 'params.rfqId');
  const supplyGroupId = get(match, 'params.group');
  const businessId = get(match, 'params.businessId');
  const customerId = get(location, 'query.customer');
  const simplifiedInquiryId = get(location, 'query.simplified_inquiry');

  useEffect(() => {
    const hasFetch = isFunction(route.fetch);
    async function getData() {
      await route.fetch(
        { rfqId, supplyGroupId, customerId, businessId, simplifiedInquiryId },
        match
      );
      setDataFetched(true);
    }
    if (isRouteRendered && hasFetch && !isDataFetched) {
      getData();
    } else if (!hasFetch) {
      setDataFetched(true);
    }
  }, [
    businessId,
    customerId,
    isDataFetched,
    isRouteRendered,
    match,
    rfqId,
    route,
    simplifiedInquiryId,
    supplyGroupId,
  ]);

  useEffect(() => {
    setRendered(Math.random());
  }, []);

  const HeaderComp = get(activeRouteData, 'navComponent')
    ? get(activeRouteData, 'navComponent')
    : TopicHeader;

  return (
    <>
      <FullModalWrapper
        className="full-modal-wrapper"
        location={location}
        route={route}
        match={match}
      >
        <ModalScrollContainer>
          <HeaderComp
            location={location}
            match={match}
            basePath={basePath}
            white={get(activeRouteData, 'white')}
            logo={get(activeRouteData, 'logo')}
            icon={get(activeRouteData, 'icon')}
            title={get(activeRouteData, 'title')}
            done={get(activeRouteData, 'done')}
            closeModal={closeModal}
          />
          {isRouteRendered && isDataFetched ? (
            <Switch>
              {renderRoutes(routes, { originTable, originSearch })}
            </Switch>
          ) : null}
        </ModalScrollContainer>
      </FullModalWrapper>
      <InPageOverlay
        portal
        showHeader
        title="Confirm close"
        onClose={() => setTransitionOpen(false)}
        open={transitionOpen}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <GridRow>
            <Col style={{ margin: '20px 40px', maxWidth: '500px' }}>
              <MD slate>
                {closeModalWarning ||
                  'Are you sure you want to close this window? You may have unsaved changes.'}
              </MD>
            </Col>
          </GridRow>
          <ButtonRow style={{ margin: '30px' }}>
            <ConfirmButtonCol>
              <Button primary type="button" onClick={handleConfirmClose}>
                I&apos;m Sure
              </Button>
            </ConfirmButtonCol>
            <CancelButtonCol>
              <Button
                type="button"
                onClick={handleCancelClose}
                style={{
                  color: 'white',
                  background: 'grey',
                  border: '1px solid grey',
                  fontWeight: 500,
                }}
              >
                Cancel
              </Button>
            </CancelButtonCol>
          </ButtonRow>
        </div>
      </InPageOverlay>
    </>
  );
}

RoutedModal.propTypes = {
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  route: PropTypes.object,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
};

export default PageViewWrapper(RoutedModal, { pageName: 'RoutedModal' });
