import React from 'react';
import Icon from '../../Icon/Icon';
import { variables } from '../../../theme/variables';

const { custom_blue: customBlue, color_grey_600: colorGrey600 } = variables;

export const facilitatorSideBarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Icon fontAwesome icon="house" color={customBlue} fontSize="16px" />,
  },
  {
    title: 'Opportunities',
    // to keep the data consistent; path is set as null if we do not need for this to be a link
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        icon="chevron-down"
        iconPrefix="far"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Supply',
        path: '/dashboard/supply/all',
        icon: <Icon fontAwesome icon="cube" color={colorGrey600} />,
      },
      {
        title: 'Demand',
        path: '/dashboard/opportunities',
        icon: <Icon fontAwesome icon="shopping-cart" color={colorGrey600} />,
      },
      {
        title: 'Market Analysis',
        path: '/dashboard/market-comparables',
        icon: <Icon fontAwesome icon="chart-line" color={colorGrey600} />,
      },
    ],
  },
  {
    title: 'Engagement Management',
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-down"
        fontSize="12px"
        color={colorGrey600}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={colorGrey600}
      />
    ),
    subNav: [
      {
        title: 'Engagements',
        path: '/dashboard/project-management/engagements',
        icon: <Icon fontAwesome icon="clipboard" color={colorGrey600} />,
      },
      {
        title: 'Interactions',
        path: '/dashboard/project-management/interactions',
        icon: <Icon fontAwesome icon="megaphone" color={colorGrey600} />,
      },
      {
        title: 'Action Items',
        path: '/dashboard/project-management/action-items',
        icon: (
          <Icon fontAwesome icon="clipboard-list-check" color={colorGrey600} />
        ),
      },
    ],
  },
  {
    title: 'CRM',
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-down"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Attributes & Tags',
        path: '/dashboard/attributes',
        icon: (
          <Icon fontAwesome icon="tag" color={colorGrey600} fontSize="14px" />
        ),
      },
      {
        title: 'Contacts',
        path: '/dashboard/users',
        icon: <Icon fontAwesome icon="users" color={colorGrey600} />,
      },
      {
        title: 'Companies',
        path: '/dashboard/companies',
        icon: <Icon fontAwesome icon="building" color={colorGrey600} />,
      },
      {
        title: 'Wishlist',
        path: '/dashboard/wish-list',
        icon: <Icon fontAwesome icon="heart" color={colorGrey600} />,
      },
    ],
  },
  {
    title: 'Commercial Enablement',
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-down"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Campaigns',
        path: '/dashboard/campaigns',
        icon: <Icon fontAwesome icon="envelope" color={colorGrey600} />,
      },
      {
        title: 'Listings',
        path: '/dashboard/listings',
        icon: <Icon fontAwesome icon="browser" color={colorGrey600} />,
      },
      {
        title: 'Content',
        path: '/dashboard/content-editor',
        icon: <Icon fontAwesome icon="book" color={colorGrey600} />,
      },
    ],
  },
  {
    title: 'Market Information',
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-down"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Scrap Value',
        path: '/dashboard/scrap-value',
        icon: <Icon fontAwesome icon="chart-line" color={colorGrey600} />,
      },
    ],
  },
];

export const partnerSideBarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Icon fontAwesome icon="house" color={customBlue} fontSize="16px" />,
  },
  {
    title: 'Opportunities',
    // to keep the data consistent; path is set as null if we do not need for this to be a link
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        icon="chevron-down"
        iconPrefix="far"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Supply',
        path: '/dashboard/supply/groups',
        icon: <Icon fontAwesome icon="cube" color={colorGrey600} />,
      },
    ],
  },
  {
    title: 'CRM',
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-down"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Contacts',
        path: '/dashboard/users/partner-accounts',
        icon: <Icon fontAwesome icon="users" color={colorGrey600} />,
      },
    ],
  },
  {
    title: 'Messaging',
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-down"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Manager Conversation',
        path: '/dashboard/partner-manager-conversation',
        icon: <Icon fontAwesome icon="comments" color={colorGrey600} />,
      },
    ],
  },
];

export const endUserSideBarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Icon fontAwesome icon="house" />,
  },
  {
    title: 'Global Warehouse',
    path: '/dashboard/listings',
    icon: <Icon fontAwesome icon="globe" />,
  },
  {
    title: 'Activity',
    path: '/dashboard/activity',
    icon: <Icon fontAwesome icon="chart-network" />,
  },
  {
    title: 'My Requests',
    path: '/dashboard/my-requests',
    icon: <Icon fontAwesome icon="paper-plane" />,
  },
  {
    title: 'My Supply',
    path: '/dashboard/supply',
    icon: <Icon fontAwesome icon="database" />,
  },
  {
    title: 'Technical Resources',
    path: '/dashboard/technical',
    icon: <Icon fontAwesome icon="toolbox" />,
  },
  {
    title: 'Wish Lists',
    path: '/dashboard/wish-list',
    icon: <Icon fontAwesome icon="star" />,
  },
  {
    title: 'Conversations',
    path: '/dashboard/conversations',
    icon: <Icon fontAwesome icon="comments" />,
  },
];

export const adminSideBar = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <Icon fontAwesome icon="house" />,
  },
  {
    title: 'Inventory',
    path: '/dashboard/inventory',
    icon: <Icon fontAwesome icon="dice-two" />,
  },
  {
    title: 'Opportunities',
    path: '/dashboard/opportunities',
    icon: <Icon fontAwesome icon="edit" />,
  },
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: <Icon fontAwesome icon="users" />,
  },
  {
    title: 'Blog',
    path: '/dashboard/content-editor',
    icon: <Icon fontAwesome icon="icon-fileboard" />,
  },
  {
    title: 'Help Center',
    externalPath: 'https://pipesearchhelp.zendesk.com/hc/en-us',
    icon: <Icon fontAwesome icon="icon-done-outline" />,
  },
  {
    title: 'Messages',
    path: '/dashboard/messages',
    icon: <Icon fontAwesome icon="icon-message" />,
  },
];

export const settingsSideBarAdditionalData = [
  {
    title: 'Settings',
    // to keep the data consistent; path is set as null if we do not need for this to be a link
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        icon="chevron-down"
        iconPrefix="far"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Business',
        path: '/settings/business',
        icon: <Icon fontAwesome icon="building" color={colorGrey600} />,
      },
      {
        title: 'Areas of Interest',
        path: '/settings/locations-of-interest',
        icon: <Icon fontAwesome icon="map-marker-alt" color={colorGrey600} />,
      },
      {
        title: 'Account',
        path: '/settings/account',
        icon: <Icon fontAwesome icon="user" color={colorGrey600} />,
      },
    ],
  },
];

export const settingsFacilitatorSideBarAdditionalData = [
  {
    title: 'Settings',
    // to keep the data consistent; path is set as null if we do not need for this to be a link
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        icon="chevron-down"
        iconPrefix="far"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Business',
        path: '/settings/business',
        icon: <Icon fontAwesome icon="building" color={colorGrey600} />,
      },
      {
        title: 'Areas of Interest',
        path: '/settings/locations-of-interest',
        icon: <Icon fontAwesome icon="map-marker-alt" color={colorGrey600} />,
      },
      {
        title: 'Account',
        path: '/settings/account',
        icon: <Icon fontAwesome icon="user" color={colorGrey600} />,
      },
      {
        title: 'Activity',
        path: '/dashboard/activity',
        icon: <Icon fontAwesome icon="list" color={colorGrey600} />,
      },
      {
        title: 'Permissions',
        path: '/settings/permissions',
        icon: <Icon fontAwesome icon="user-lock" color={colorGrey600} />,
      },
      {
        title: 'Attributes',
        path: '/settings/attributes',
        icon: <Icon fontAwesome icon="tag" color={colorGrey600} />,
      },
    ],
  },
];

export const settingsEndUserSideBarAdditionalData = [
  {
    title: 'Settings',
    // to keep the data consistent; path is set as null if we do not need for this to be a link
    path: null,
    icon: '',
    iconClosed: (
      <Icon
        fontAwesome
        icon="chevron-down"
        iconPrefix="far"
        fontSize="12px"
        color={customBlue}
      />
    ),
    iconOpened: (
      <Icon
        fontAwesome
        iconPrefix="far"
        icon="chevron-up"
        fontSize="12px"
        color={customBlue}
      />
    ),
    subNav: [
      {
        title: 'Business',
        path: '/settings/business',
        icon: <Icon fontAwesome icon="building" color={colorGrey600} />,
      },
      {
        title: 'Areas of Interest',
        path: '/settings/locations-of-interest',
        icon: <Icon fontAwesome icon="map-marker-alt" color={colorGrey600} />,
      },
      {
        title: 'Account',
        path: '/settings/account',
        icon: <Icon fontAwesome icon="user" color={colorGrey600} />,
      },
    ],
  },
];
