import styled from 'styled-components/macro';

export const WrapperInner = styled('div')`
  position: relative;
  .DraftEditor-root {
    border: 1px solid #ececec;
    min-height: 120px;
    overflow: auto;
    background: #fff;
    margin-bottom: 0;
    padding: 20px 20px;
    position: relative;
    max-height: 300px;
    overflow: scroll;
    @media (max-width: 600px) {
      min-height: 250px;
    }
  }
  .public-DraftEditor-content {
    min-height: 90px;
  }
  .public-DraftEditor-content div div {
    margin-bottom: 10px;
  }
  .public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none;
    opacity: 0.3;
    display: ${(props) => (props.hidePlaceholder ? 'none' : 'block')};
  }
  .draftJsToolbar__toolbar__dNtBH {
    border: none;
    padding: 5px 15px;
    border-radius: 0px;
    box-shadow: none;
    background: #ececec;
    margin-bottom: 0;
  }
  .draftJsToolbar__button__qi1gf {
    background: transparent;
    border: 1px solid transparent;
    svg {
      width: 20px;
      max-width: 20px;
      min-width: 20px;
      transform: scale(0.9);
      margin: 0 auto;
    }
  }

  ul {
    display: block;
    list-style-type: disc !important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    display: block;
    list-style-type: decimal !important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  blockquote {
    border-left: 2px solid #a2a2a2;
    color: #5d5d5d;
    font-style: italic;
    margin: 12px 0 40px 0;
    padding: 6px 0 5px 18px;
  }
`;

export const Wrapper = styled('div')`
  margin-top: 10px;

  .draftJsLinkifyPlugin__link__2ittM,
  .draftJsLinkifyPlugin__link__2ittM:visited {
    color: #5e93c5;
    text-decoration: none;
  }

  .draftJsLinkifyPlugin__link__2ittM:hover,
  .draftJsLinkifyPlugin__link__2ittM:focus {
    color: #7eadda;
    outline: 0; /* reset for :focus */
    cursor: pointer;
  }

  .draftJsLinkifyPlugin__link__2ittM:active {
    color: #4a7bab;
  }

  .draftJsToolbar__buttonWrapper__1Dmqh {
    display: inline-block;
  }

  .draftJsToolbar__button__qi1gf {
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
  }

  .draftJsToolbar__button__qi1gf svg {
    fill: #888;
  }

  .draftJsToolbar__button__qi1gf:hover,
  .draftJsToolbar__button__qi1gf:focus {
    background: #f3f3f3;
    outline: 0; /* reset for :focus */
  }

  .draftJsToolbar__active__3qcpF {
    background: #efefef;
    color: #444;
  }

  .draftJsToolbar__active__3qcpF svg {
    fill: #444;
  }
  .draftJsToolbar__separator__3U7qt {
    display: inline-block;
    height: 24px;
    margin: 0 0.5em;
  }
  .draftJsToolbar__toolbar__dNtBH {
    z-index: 2;
    box-sizing: border-box;
  }

  .draftJsToolbar__toolbar__dNtBH:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px;
  }
  .draftJsToolbar__toolbar__dNtBH:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #ddd;
    border-width: 6px;
    margin-left: -6px;
  }
`;
