import asyncTreeRequester from 'utility/asyncTreeRequester';
import tree from 'state';
import * as effects from './effects';

const activityCursor = tree.select(['activity']);

export async function fetchActivityLog(
  conversationId = '',
  { page, limit } = {}
) {
  return asyncTreeRequester({
    func: effects.fetchActivityLog.bind(this, conversationId, { page, limit }),
    cursor: activityCursor,
    path: ['log'],
  });
}

export async function fetchActivityFeed(
  feedId,
  { page, limit, feedGroup } = {}
) {
  return asyncTreeRequester({
    func: effects.fetchActivityFeed.bind(this, feedId, {
      page,
      limit,
      feedGroup,
    }),
    cursor: activityCursor,
    path: ['activityFeed'],
  });
}
