import tree from 'state';
import asyncTreeRequester, {
  emptyAsyncTreeRequesterOptions,
} from 'utility/asyncTreeRequester';
import { Filters } from 'types/types.ts';
import * as effects from './effects.ts';

const inventoryCursor = tree.select(['scrapValue']);

interface GetScrapValueOptions {
  pagination?: {};
  filters?: Filters;
  sorting?: {};
}

export async function getScrapValue({
  pagination,
  filters,
  sorting,
}: GetScrapValueOptions = {}) {
  const response = await asyncTreeRequester({
    ...emptyAsyncTreeRequesterOptions,
    func: effects.getScrapValue,
    pagination,
    filters,
    sorting,
    cursor: inventoryCursor,
    path: ['scrapValue'],
  });

  return response;
}

export async function getScrapValueSpreadsheet(
  data: effects.GetScrapSpreadSheetPayload
) {
  let response;
  try {
    response = await effects.getScrapSpreadsheet(data);
  } catch (error) {
    const _error = error as Error;
    response = {
      error: _error.message,
    };
  }
  return response;
}

export async function uploadScrapValueSpreadsheet(
  data: effects.UploadScrapSpreadsheetPayload
) {
  let response;
  try {
    response = await effects.uploadScrapSpreadsheet(data);
  } catch (error) {
    const _error = error as Error;
    response = {
      error: _error.message,
    };
  }
  return response;
}
