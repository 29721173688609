import React from 'react';
import { map, noop } from 'lodash';
import Flex from 'styled-flex-component';
import DocumentDownload from 'components/DocumentDownload/DocumentDownload';
import PropTypes from 'prop-types';
import ThumbnailDownload from 'components/DocumentDownload/ThumbnailDownload';

function DropZoneFileRenderer({
  onClickThumbnail,
  files,
  onRemoveFile,
  disableDelete,
  isImage,
}) {
  return (
    <div className="dropzone-fileloader">
      <Flex wrap="wrap" className="file-list">
        {map(files, (f, i) => {
          const title = f.saved.original_file_name;
          const type = title ? title.split('.').pop() : '';
          const fileId = f.saved.id;
          return isImage ? (
            <div
              role="button"
              className="file-list-item"
              onKeyDown={noop}
              tabIndex={0}
              onClick={
                onClickThumbnail
                  ? onClickThumbnail.bind(this, { data: f, key: i })
                  : null
              }
              key={i}
            >
              <ThumbnailDownload
                modal={!onClickThumbnail}
                disableDelete={disableDelete}
                onRemoveFile={(e) => {
                  e.stopPropagation();
                  onRemoveFile({ data: f, key: i });
                }}
                documentItem={{ title, type, fileId }}
                key={i}
                filesize={f.saved.size}
              />
            </div>
          ) : (
            <div
              className="file-list-item"
              style={{
                maxWidth: '350px',
                minWidth: '250px',
                padding: '5px 12px',
                marginLeft: '-12px',
              }}
              key={i}
            >
              <DocumentDownload
                disableDelete={disableDelete}
                onRemoveFile={() => onRemoveFile({ data: f, key: i })}
                documentItem={{ title, type, fileId }}
                filesize={f.saved.size}
              />
            </div>
          );
        })}
      </Flex>
    </div>
  );
}

DropZoneFileRenderer.propTypes = {
  onClickThumbnail: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.object),
  onRemoveFile: PropTypes.func,
  disableDelete: PropTypes.bool,
  isImage: PropTypes.bool,
};

export default DropZoneFileRenderer;
